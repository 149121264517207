import type { Root } from '../../types/dashboard';

import { Loader } from '../../components/Loader';
import { useCredentials } from '../../hooks/useCredentials';
import { useUser, useUserProfilePicture } from '../../hooks/useUser';
import { NullBadgeUrl, getUserBasicData } from "../../tools/utils";
import useIsMobile from '../../hooks/Helper/useIsMobile';
import { useGlobalContext } from '../../hooks/Helper/GlobalContext';
import { Link } from 'react-router-dom';
import { Credentials } from '../../types/allcredential';
import React from 'react';
import { NotificationBanner } from '../../components/NotificationBanner/NotificationBanner';
import { ContactCredentialCard } from '../../components/Cards/ContactCredentialCard';
import { useTranslation } from 'react-i18next';

export const TestingPersonnel = ({ headerMarginTop }: { headerMarginTop: number }) => {
  const {ProviderAccessControl,Color,ProviderBannerColor,ProviderBannerMessage,FullName,CandidateId }=useGlobalContext();
  const {t}=useTranslation();
  const isMobile = useIsMobile();
  const credentials = useCredentials();
  const userCredentials = credentials?.data?.data?.Data;
  const credentialGroupedData = userCredentials && userCredentials?.MyCredentials
    .sort((a, b) => a.EarnedCredential.GroupOrder - b.EarnedCredential.GroupOrder)
    .reduce((groups: any, item) => {
      const { GroupName } = item.EarnedCredential;
      if (!groups[GroupName]) {
        groups[GroupName] = [];
      }
      groups[GroupName].push(item.EarnedCredential);
      return groups;
    }, {});

    const credentialGroup: Array<{ [x: string]: unknown; }> = credentialGroupedData && Object.entries(credentialGroupedData).map(([GroupName, groupItems]) => ({
      [GroupName]: groupItems,
    }));
    const candidateBannerVisible = ProviderAccessControl ? (ProviderAccessControl !== 'Normal' && ProviderAccessControl !== 'Watched') : false;
  
    const getMarginTop = () => {
      if ( candidateBannerVisible && isMobile) {
          return "230px";  // Both banners are visible on mobile
      } 
    
   else {
          return "";  // No banner is visible
      }
  };
  return (
    <div id="main-content" style={{ marginTop: headerMarginTop }}>
    <div className="cco_content_area cco_content_area_0">
    <article className="react-container" >
    <NotificationBanner
                background={ProviderBannerColor}
                showBanner={ProviderAccessControl ?(ProviderAccessControl !== 'Normal' && ProviderAccessControl !== 'Watched') : false}
                message={ProviderBannerMessage}
                isPhotoBanner={false}
                type="ac"
                color={Color}
                isMobile={isMobile}
            />

      <div        className={ (ProviderAccessControl && ProviderAccessControl !== 'Normal' && ProviderAccessControl !== 'Watched')
                        ? 'cco_section bannerVisible'
                        : 'cco_section cco_section_3'
                }>
{ProviderAccessControl !== 'Locked' && (
   <>
   <div  style={{
                    marginTop: getMarginTop()}}>
      <div className="cco_row cco_row_4"  >
        <div className="cco_col">
          <div className="cco_text cco_text_0">
            <div className="cco_text_inner">
              <strong>{t("testingPersonnel.pageHeading")}</strong>
            </div>
          </div>
        </div>
      </div>

      <div className="cco_row cco_row_0">
        <>
          {/* <div className="cco_col cco_col_5"> */}
          <div className="cco_col cco_col_12">
            <div className="cco_text cco_text_1">
              <div className="cco_text_inner">
                <h1>
               <strong> {FullName}</strong>
                  <span className="divider" />
                  <small>
                  {t("testingPersonnel.ccoId")} <strong>{CandidateId}</strong>
                  </small>
                </h1>
              </div>
            </div>
          </div>
        </>
      </div>

      {credentialGroup && credentialGroup.map((group: any, index: number) => (
        <React.Fragment key={group + index}>
          <div className="cco_row cco_row_7">
            <div className="cco_col cco_col_6">
              <div className="cco_text cco_text_10">
                <div className="cco_text_inner">
                  <h2><strong>My {Object.keys(group)[0]}</strong></h2>
                </div>
              </div>
            </div>
          </div>

          <div className="cco_row cco_row_38">
            <div className="cco_col">
              {credentials.isLoading ? (
                <Loader />
              ) : credentials.error ? (
                <p>{credentials.error.message}</p>
              ) : group && group[Object.keys(group)[0]].length > 0 ? (
                <div className="cco_group cco_group_myCredential" >
                  {group[Object.keys(group)[0]]
                  .sort((a: { CredentialOrder: number; }, b: { CredentialOrder: number; }) => a.CredentialOrder - b.CredentialOrder)
                  .map((credential: Credentials,index:number) => (
                                                          
                    <ContactCredentialCard 
                    credential={credential}
                    index={index}
                    ProviderAccessControl={ProviderAccessControl}
                    />
                  ))}
          </div>
              ) : (
                <p>{t("testingPersonnel.noCredential")}</p>
              )}
            </div>
          </div>
        </React.Fragment>
      ))}




      {/*Available Credential  */}
      <div className="cco_row cco_row_2">
        <div className="cco_col">
          <div className="cco_text cco_text_2">
            <div className="cco_text_inner">
              {/* <h2>Available Credentials</h2> */}

            </div>
          </div>
        </div>
      </div>
  {ProviderAccessControl !== 'Suspended' && (
  <> 

      {credentials.isLoading ? (
        <div className='cco_row cco_row_3'>
          <Loader />
        </div>
      ) : credentials.error ? (
        <p>{credentials.error.message}</p>
      ) : (
        <div className="cco_row cco_row_3" style={{ backgroundColor: 'white' }}>
          <div className="cco_col">
            <div className="cco_group cco_group_2" >
              <div className="cco_group_item" >
                <ul className="cco_menu cco_menu_6" style={{ textAlign: 'center' }}>
                  <li className="cco_menu_item cco_menu_item_primary" style={{ marginBottom: "0px" }}  >
                  <a
                    className={`${ProviderAccessControl === 'Suspended' ? 'btn-disabled' : ''}`}
                    style={{ height: "auto" }}
                    href={ProviderAccessControl === 'Suspended' ? '' : '/credential-journey'}
                    onClick={(e) => {
                      if (ProviderAccessControl === 'Suspended') {
                        e.preventDefault();
                      }
                    }}
                    
                  >{t("testingPersonnel.credentialJourneyButton")}</a>
                    <div style={{ textAlign: "center" }}>
                      <span>
                      <a
                       className='ccolinks'
                       style={{ color: ProviderAccessControl === 'Suspended' ? 'grey' : '' }}
                       href={ProviderAccessControl === 'Suspended' ? '' : '/available-credentials'}
                       onClick={(e) => {
                        if (ProviderAccessControl === 'Suspended') {
                          e.preventDefault();
                        }
                      }}
                      >{t("testingPersonnel.availableCredentialButton")}</a>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
 </>
   )}
</div>
    </>
    
)}
    </div> 
  
    </article>
    </div>
    </div>
  );
};