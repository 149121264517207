import { Formik } from "formik";
import { useEffect, useState } from "react";
import { NullBadgeUrl } from "../../tools/utils";
import { Loader } from "../../components/Loader";
import { CredentialGroups } from "../../types/credentialgroups";
import { useCredentialGroups } from "../../hooks/useCredentialGroups";
import { useGroupCredentials } from "../../hooks/useGroupCredentials";
import { Link } from "react-router-dom";
import { AccessControl, useGlobalContext } from '../../hooks/Helper/GlobalContext';
import { useTranslation } from "react-i18next";

export const CredentialJourney = ({ headerMarginTop }: { headerMarginTop: number }) => {
  const credentialGroupsQuery = useCredentialGroups();
  const credentialGroupsData = credentialGroupsQuery?.data?.data
    ?.Data as CredentialGroups[];

  console.log(credentialGroupsData);
  const {t}=useTranslation();
  const [selectedCredentialType, setSelectedCredentialType] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedCredential, setSelectedCredential] = useState({
    id: "default",
    name: "",
  });
  const groupCredentialQuery = useGroupCredentials(
    "default" === selectedCredential.id ? undefined : selectedCredential.id
  );
  const groupCredentialData = groupCredentialQuery?.data?.data?.Data;

  const handleCredentialTypeSelect = (type: any) => {
    setSelectedCredentialType(type);
    setSelectedRole(null);
  };

  // const handleBack = () => {
  //     setSelectedCredentialType(null)
  //     setSelectedRole(null)
  //     setSelectedCredential({id:'default',name:''})

  // }

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [isLoading]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{}}
      validationSchema={{}}
      onSubmit={() => {}}
    >
      {({
        values,
        /*setFieldValue,*/ isSubmitting,
        handleSubmit,
        handleReset,
      }) => (
        <form
          className="cco_form"
          onReset={handleReset}
          onSubmit={(e) => {
            console.log(values);
            if (isSubmitting) e.preventDefault();
            else handleSubmit(e);
          }}
        >
          <div id="main-content" style={{ marginTop: headerMarginTop }}>
            <div className="cco_content_area cco_content_area_0">
              <article className="react-container">
                <div className="cco_section cco_section_0">
                  <div className="cco_row">
                    <div className="cco_col">
                      <div className="cco_row cco_row_8">
                        <div className="cco_col">
                          <div className="cco_text cco_text_11">
                            <div className="cco_text_inner">
                              <strong>
                                <a href="/testing-personnel">
                         {t("credentialjourney.testingPersonnel")}
                                </a>
                              </strong>{" "}
                              &gt;   {t("credentialjourney.CredentialJourney")}
                            </div>
                          </div>
                        </div>
                      </div>

                      <>
                        <div className="cco_row cco_row_7">
                          <div className="cco_col cco_col_6">
                            <div className="cco_text cco_text_10">
                              <div className="cco_text_inner">
                                <h2><strong>
                                  {selectedCredential.name
                                    ? selectedCredential.name
                                    : t("credentialjourney.SelectJourneyButton")} </strong>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>

                        {credentialGroupsQuery.isLoading ||
                        ("default" === selectedCredential.id
                          ? null
                          : groupCredentialQuery.isLoading) ? (
                          <div className="cco_form_wrap">
                            <Loader />
                          </div>
                        ) : credentialGroupsQuery.error ||
                          groupCredentialQuery?.error ? (
                          <div className="cco_form_wrap">
                            <p>
                              {credentialGroupsQuery.isError &&
                                credentialGroupsQuery.error.message}
                            </p>
                            <p>
                              {groupCredentialQuery?.isError &&
                                groupCredentialQuery?.error.message}
                            </p>
                          </div>
                        ) : (
                          <div className="cco_row cco_row_3">
                            <div className="cco_col">
                              {!selectedCredentialType && (
                                <>
                                  <div
                                    className="cco_group cco_group_2"
                                    style={{
                                      flexDirection: "column",
                                      justifyContent: "start",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {credentialGroupsData
                                      .sort(
                                        (a, b) =>
                                          a.CredentialGroupOrder -
                                          b.CredentialGroupOrder
                                      )
                                      .map((credential: CredentialGroups) => (
                                        <div
                                          className="cco_group_item tpCards"
                                          key={credential.CredentialGroupId}
                                          style={{ margin: "10px" }}
                                        >
                                          <ul className="cco_menu cco_menu_7 ">
                                            <li className="cco_menu_item ">
                                              <a
                                                href={"javascript:void(0)"}
                                                className="customHover"
                                                onClick={() => {
                                                  handleCredentialTypeSelect(
                                                    credential.CredentialGroupName
                                                  );
                                                  setSelectedCredential({
                                                    id: credential.CredentialGroupId,
                                                    name: credential.CredentialGroupName,
                                                  });
                                                }}
                                              >
                                                {credential.CredentialGroupName}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      ))}
                                  </div>
                                </>
                              )}

                              {!selectedRole &&
                                groupCredentialQuery.isFetched && (
                                  <>
                                    {/* <a href={"javascript:void(0)"} className='' style={{marginBottom:'40px'}} onClick={handleBack}>Back</a> */}
                                    {groupCredentialData &&
                                    groupCredentialData?.AvailableCredentials
                                      .length > 0 ? (
                                      <div
                                        className="cco_group cco_group_2"
                                        style={{
                                          flexDirection: "column",
                                          justifyContent: "start",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        {groupCredentialData?.AvailableCredentials.map(
                                          (credential) => (
                                            <div
                                              className="cco_group_item tpCards"
                                              key={credential.CredentialId}
                                              style={{ margin: "10px" }}
                                            >
                                              <ul className="cco_menu cco_menu_7 ">
                                                <li className="cco_menu_item ">
                                                  <Link
                                                  className="customHover"
                                                    to={"/credential/"+credential.CredentialId}
                                                    
                                                 
                                                    // style={{
                                                    //   color:
                                                    //     "rgb(102, 102, 102)",
                                                    // }}
                                                  >
                                                    {credential.myCCOLabel}
                                                  </Link>

                                                  {/* <a href={"/credential/" + credential.CredentialId} className='customHover'>{credential.myCCOLabel}</a> */}
                                                </li>
                                              </ul>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <p>No Credentials available</p>
                                    )}
                                  </>
                                )}
                            </div>
                          </div>
                        )}

                        {selectedCredentialType && (
                          <>
                            <div className="cco_row cco_row_7">
                              <div className="cco_col cco_col_6">
                                <div className="cco_text cco_text_10">
                                  <div className="cco_text_inner">
                                    {groupCredentialData && (
                                      <h2><strong> My {selectedCredential.name} </strong></h2>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="cco_row cco_row_3">
                              <div className="cco_col">
                                {groupCredentialQuery.isLoading ? (
                                  <Loader />
                                ) : groupCredentialQuery.error ? (
                                  <p>{groupCredentialQuery.error.message}</p>
                                ) : groupCredentialData?.MyCredentials &&
                                  groupCredentialData?.MyCredentials.length >
                                    0 ? (
                                  <div className="cco_group cco_group_myCredential">
                                    {groupCredentialData.MyCredentials.map(
                                      (credential: any) => (
                                        <div
                                          className="cco_group_item tpCards"
                                          key={
                                            credential.EarnedCredential
                                              .ContactCredentialId
                                          }
                                        >
                                          {/* <a
                                            href={
                                              "/my-credential/" +
                                              credential?.EarnedCredential
                                                .ContactCredentialId
                                            }
                                          > */}

                                          <Link
                                            to={"/my-credential/"+credential.EarnedCredential.ContactCredentialId}
                                          
                                            // state={{
                                            //   id: credential?.EarnedCredential
                                            //     .ContactCredentialId,
                                            // }}
                                          >
                                            <div className="cco_card cco_card_myCredential">
                                              <div className="cco_card_body">
                                                <div className="cco_card_aside">
                                                  <div className="cco_img">
                                                    <span className="cco_img_wrap">
                                                      <img
                                                        style={{
                                                          border:
                                                            credential
                                                              ?.EarnedCredential
                                                              .StatusReason ===
                                                              "Approved" &&
                                                            credential
                                                              ?.EarnedCredential
                                                              .BadgeUrl != null
                                                              ? ""
                                                              : "1px solid rgb(0 0 0 / 5%)",
                                                          borderRadius:
                                                            credential
                                                              ?.EarnedCredential
                                                              .StatusReason ===
                                                              "Approved" &&
                                                            credential
                                                              ?.EarnedCredential
                                                              .BadgeUrl != null
                                                              ? ""
                                                              : "23px",
                                                        }}
                                                        alt={
                                                          credential
                                                            ?.EarnedCredential
                                                            ?.myCCOLabel
                                                        }
                                                        src={
                                                          credential
                                                            ?.EarnedCredential
                                                            .StatusReason ===
                                                            "Approved" &&
                                                          credential
                                                            ?.EarnedCredential
                                                            .BadgeUrl != null
                                                            ? credential
                                                                ?.EarnedCredential
                                                                .BadgeUrl
                                                            : NullBadgeUrl
                                                        }
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                                <div
                                                  className="cco_card_main"
                                                  style={{
                                                    width: "100%",
                                                    padding: "10px",
                                                  }}
                                                >
                                                  <div className="cco_text cco_text_myCredential">
                                                    <div className="cco_text_inner">
                                                      <h3>
                                                        <strong>
                                                        {
                                                          credential
                                                            ?.EarnedCredential
                                                            .myCCOLabel
                                                        } </strong>
                                                      </h3>
                                                      <div>
                                                        Status:{" "}
                                                        {String(t(`cards.ContactCredentialCard.Statuses.${credential?.EarnedCredential.StatusReason}`,credential?.EarnedCredential.StatusReason))}
                                                      </div>
                                                      <div>
                                                        Training Due:{" "}
                                                        {credential?.EarnedCredential.ExpiryDate?.split(
                                                          "T"
                                                        )[0] === "0001-01-01"
                                                          ? "Not Available"
                                                          : credential?.EarnedCredential.ExpiryDate?.split(
                                                              "T"
                                                            )[0]}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Link>
                                          {/* </a> */}
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <p>No Credentials available</p>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
