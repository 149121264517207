import { useCredential } from '../../hooks/useCredential';
import  { useState } from 'react';
import { BlockUI, Loader } from '../../components/Loader';
import { HostedForm } from 'react-acceptjs';
import {  credentialRenewalProps } from '../../types/credential';
import { ErrorMessage, Field, Formik } from 'formik';
import { createcredentialSchema } from '../../schemas/credential';
import InputMask from 'react-input-mask';
import { useAuthData } from '../../hooks/useAuthData';
import { usePrerequisiteCredential } from '../../hooks/usePrerequisiteCredential';
import AuthorizeSessionTimer from '../../components/PaymentTimer/AuthorizeSessionTimer';
import { AvailableCredential } from '../../types/allcredential';
import { usePrerequisiteTree } from '../../hooks/usePrerequisiteTree';
import { useMyCredential } from '../../hooks/useMyCredential';
import { useCredentialRenewal } from '../../hooks/useCredentialRenewal';
import { useCredentialRenewalValidate } from '../../hooks/useCredentialRenewalValidate';
import { getContactId, StatusReason } from '../../tools/utils';
import { PreReqCredentialCard } from '../../components/Cards/PreRequisiteCard';
import { useTranslation } from 'react-i18next';



export const CredentialRenewal = ( { headerMarginTop }: { headerMarginTop: number }) => {
    const authQuery = useAuthData('contact-credential');
    const contactId =getContactId();
    // const {eot} =useLocation().state;
    const  id  =  window.location.pathname.split('/')[2] || '';
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const myCredentialQuery = useMyCredential(id);
    const myCredentialData = myCredentialQuery.data?.data?.Data;
    const useCredentialQuery = useCredentialRenewalValidate(id);
    const useCredentialData = useCredentialQuery.data?.data;
    const credentials = useCredential(myCredentialData?.EarnedCredential.Id ,language);
    const preRequisiteQuery = usePrerequisiteCredential(myCredentialData ? myCredentialData?.EarnedCredential.Id:'',language);
 
  
    const preRequisiteTreeQuery = usePrerequisiteTree(myCredentialData ? myCredentialData?.EarnedCredential.Id:'',language);

    const AuthData = authQuery.data?.data.Data;
    const credentialRenewal = useCredentialRenewal();

    const preRequisiteData = preRequisiteQuery.data?.data?.Data;
  
    const preRequisiteTreeData = preRequisiteTreeQuery.data?.data?.Data;
    const preRequisiteTreeDataReversed = preRequisiteTreeData?.reverse();
    // console.log(useCredentialData)
    // console.log('preRequisiteData')
    // console.log(preRequisiteData)
    // console.log('preRequisiteTreeData')
    // console.log(preRequisiteTreeData)
    const credentialObj = credentials.data?.data?.Data as AvailableCredential;
    // const  = userCredentials && userCredentials  ;
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [checkout, setcheckout] = useState(false);
    const [isCreditInfo, setCreditInfo] = useState(false);
    const [creditData, setCreditData] = useState({ LastFour: '', dataDescriptor: '', dataValue: '', CardNumber: '' });
    let credentialRenewalObj: credentialRenewalProps = {
        ContactId:contactId,
        ContactCredentialId:id,
        Payment: {
            Amount: 0,
            AuthNetTransactionID: "",
            CreditCardType: "",
            LastFour: '',
            Paymenttype: "Credit Card",
            PaymentData: {
                dataDescriptor: '',
                dataValue: '',
                Address: '',
                City: '',
                FirstName: '',
                LastName: '',
                State: '',
                Zip: '',
                BillToEmail: '',
                BillToName: '',
                BillingPhone: '',
                BillingCountry: ''
            }
        }
    }
    const initialValues = {
        Address: '',
        BillToEmail: '',
        City: '',
        FirstName: '',
        LastName: '',
        Zip: "",
        State: "",
        BillingPhone: "",
        BillingCountry: ""
    }
    const handleSubmits = (response: any) => {
        if (response.messages.message[0].code === 'I_WC_01') {
            setCreditInfo(true);
            setCreditData(
                {
                    LastFour: response.encryptedCardData.cardNumber.replaceAll('X', ''),
                    dataDescriptor: response.opaqueData.dataDescriptor,
                    dataValue: response.opaqueData.dataValue,
                    CardNumber: response.encryptedCardData.cardNumber
                }
            );
        }

    };
    const handleTermsAndConditionsChange = (event: any) => {
        setTermsAndConditions(event.target.checked);
    };
    const processedtocheckout = (value: boolean) => {
        setcheckout(value);
    };

    function handleZeroFeeCredential() {
        const CreateCredentialsObj = {
            ContactId: contactId,
            ContactCredentialId:id,
            Payment: null
        };
        credentialRenewal.mutateAsync(CreateCredentialsObj).then((res) => {
            if (res?.data?.ApiCode === 200 && res.success === true) {
                window.location.href = "/testing-personnel/";
            }

        }).finally(() => {

        }).catch((err) => {
           console.log('zerocredError :>> ', err.message); 
         
        });
    }

    const isValidated = !termsAndConditions;
    const isDateWithinLast60Months = (dateString) => {
        const currentDate = new Date();
        const fiveYearsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5); // Subtract 5 years (60 months)
    
        const startDate = new Date(fiveYearsAgo.getFullYear(), fiveYearsAgo.getMonth(), fiveYearsAgo.getDate(), 0, 0, 0); // Start of the range
        const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59); // End of the range
    
        const testDate = new Date(dateString.split('T')[0]);
    
        return testDate >= startDate && testDate <= endDate;
    };
    
    const recentExams = credentialObj?.ExamDetails?.filter((examDetail) => 
        isDateWithinLast60Months(examDetail.TestDate)
    );
    

    return (
        <>
              <div id="main-content" style={{ marginTop: headerMarginTop }}>
            <div className="cco_content_area cco_content_area_0">
                <article className="react-container">
<div className="cco_section cco_section_0">
                <div className="cco_row cco_row_8">
                    <div className="cco_col">
                        <div className="cco_text cco_text_11">
                            <div className="cco_text_inner">
                                <strong>
                                    <a href="/testing-personnel">Testing Personnel</a>
                                </strong>{' '}
                                {'>'} {`${credentialObj?.myCCOLabel || ''} Renew`}
                                {isCreditInfo && <AuthorizeSessionTimer startTimer={isCreditInfo} />

                                }

                            </div>
                        </div>
                    </div>
                </div>

                <div className="cco_row" style={{ marginBottom: '20px' }}>
                <div className="cco_col">
                    { useCredentialQuery.isLoading? (
                        <Loader />
                    ) : useCredentialQuery.error ? (
                        <p>{useCredentialQuery.error.message}</p>
                        ):useCredentialData.Data? (
                        <div className="cco_text cco_text_12">
                            <div className="cco_text_inner">
                                <p>Our records indicate that you are not authorized to access this page. Please contact CCO if you have any questions. </p>
                            </div>
                        </div>
                    ) : null}
                </div>
                </div>
                {!useCredentialQuery.error && (useCredentialData && !useCredentialData.Data) &&  
<>   
              {!checkout && 

                    <>
                        <div className="cco_row cco_row_7">
                            <div className="cco_col cco_col_6">
                                <div className="cco_text cco_text_10">
                                    <div className="cco_text_inner">
                                        <h2>{'Journey Status'}</h2>
                                    </div>
                                </div>
                            </div>
                        </div><div className="cco_row cco_row_3">
                            <div className="cco_col">
                                {preRequisiteTreeQuery.isLoading ? (
                                    <Loader />
                                ) : preRequisiteTreeQuery.error ? (
                                    <p>{preRequisiteTreeQuery.error.message}</p>
                                ) : preRequisiteTreeDataReversed && preRequisiteTreeDataReversed.length > 0 ? (
                                    <div className="cco_group cco_group_2" style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                  { credentialObj?.RenewalExams === true  && (credentialObj?.PreReqCredentialName === 'Proctor-Practical' || credentialObj?.PreReqCredentialName === 'MBL Workshop' || credentialObj?.PreReqCredentialName === 'THO Workshop') &&
    <>
<div className="cco_group_item" style={{ marginTop: '10px' }}>
    <a href={( recentExams.length === 0 && credentialObj.CertificationDetails.length === 0) ? "/dashboard/" :  ''} style={{ textDecoration: 'none', color: ( recentExams.length === 0 &&  credentialObj.CertificationDetails.length === 0 ) ? 'red' : '#666' }}>
        <div className={`cco_card cco_card_myCredential ${
   recentExams.length === 0
        ? (credentialObj.CertificationDetails && credentialObj.CertificationDetails.length > 0 
            ? 'purchased-card' 
            : 'notcompleted-card') 
        : 'purchased-card'
}`}>
            <div className="cco_card_body">
                <div className="cco_card_main" style={{ width: "100%", padding: "10px" }}>
                    <div className="cco_text cco_text_myCredential">
                        <div className="cco_text_inner">
                        {
    credentialObj.CertificationDetails && credentialObj.CertificationDetails.length > 0 ? (
        credentialObj.CertificationDetails.map((certDetail, index) => (
            <div key={index}>
                <h3>{certDetail.CertificationTypeName}</h3>
                <div>Expiration Date: {certDetail.CertificationExpirationDate.split('T')[0] === '0001-01-01' ? 'Not Available' : certDetail.CertificationExpirationDate.split('T')[0]}</div>
            </div>
        ))
    ) :  recentExams && recentExams.length > 0 ? (
        credentialObj.ExamDetails.map((examDetail, index) => (
            <div key={index}>
                <h3>{examDetail.CredentialNameType}</h3>
                <div>Status: {examDetail.OverallStatus}</div>
                <div>Test Date: {examDetail.TestDate.split('T')[0] === '0001-01-01' ? 'Not Available' : examDetail.TestDate.split('T')[0]}</div>
            </div>
        ))
    ) : (
        <>
            <h3>Written Exam Required</h3>
            <div>Click here to sign up</div>
        </>
    )
}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</div>




         
        <div className="desktop-arrow"></div>
        <div className="mobile-arrow"></div>
    </>
}
                                        
                                        {preRequisiteTreeDataReversed.map(
                                            (credential, index) => (
                                                <>
                                                   
                                            <PreReqCredentialCard 
                                            credential={credential}
                                            index={index}
                                            key={credential.PreReqCredentialId+index}
                                            />
                                                </>

                                            )
                                        )}


                                        <div className="cco_group_item" style={{ marginTop: '10px' }}>
                                            <a href={'#'} style={{ color: '#666' }}>
                                                <div className={`cco_card cco_card_myCredential`}
                                                    style={{ backgroundColor: '#c67b05', color: '#ffffff' }}
                                                >
                                                    <div className="cco_card_body">
                                                        <div
                                                            className="cco_card_main"
                                                            style={{ width: "100%", padding: "10px" }}
                                                        >
                                                            <div className="cco_text cco_text_myCredential">
                                                                <div className="cco_text_inner">
                                                                    <h3 style={{ color: '#ffffff' }}>{credentialObj?.myCCOLabel}  </h3>
                                                                    <div>
                                                                        Status: {'Not Renewed'}
                                                                    </div>
                                                                    <div>
                                    Expiration Date:{' '}
                                    {myCredentialData?.EarnedCredential.ExpiryDate?.split(
                                        'T'
                                    )[0] === '0001-01-01'
                                        ? 'Not Available'
                                        : myCredentialData?.EarnedCredential.ExpiryDate?.split(
                                              'T'
                                          )[0]}
                                </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </a>
                                        </div>
                                        {/* <div className="cco_group_item" style={{ marginTop: '10px' }}>

        <div className={`cco_card cco_card_myCredential`} style={{ width: '100px', height: '100px', borderRadius: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div className="cco_card_body">
                <div className="cco_card_main" style={{ width: "100%", padding: "10px" }}>
                    <div className="cco_text cco_text_myCredential">
                        <div className="cco_text_inner">
                            <b style={{fontSize:'12px'}}>{credentialObj.myCCOLabel}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div> */}




                                    </div>

                                ) : (
                                    <p> There are no pre-requisites for {credentialObj && credentialObj.CredentialName} credential.</p>
                                )}
                            </div>
                        </div></>
                }


                {/* {(preRequisiteData?.Status === 'Approved' || preRequisiteData === null) && ( */}
                <>
                    {/* <div className="cco_row" style={{ marginBottom: "20px" }}>
                            <div className="cco_col">
                                {credentials.isLoading ? (<Loader />) : credentials.error ? (<p>{credentials.error.message}</p>) : (
                                    <div className="cco_card cco_card_2">
                                        <div className="cco_card_body">
                                            <div className="cco_card_main" style={{ padding: '0px' }}>
                                                <div className={'cco_text cco_text_9'}>
                                                    <div className={'cco_text_inner'}>
                                                        <h3>{credentialObj?.myCCOLabel || ''}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>
                        </div> */}


                    {checkout && termsAndConditions &&(
                        <><Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={createcredentialSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                if (isCreditInfo == true) {
                                    credentialRenewalObj = {
                                        ContactId:contactId,
                                        ContactCredentialId:id,
                                        
                                        Payment: {
                                            Amount: credentialObj.CredentialFee.Price,
                                            AuthNetTransactionID: "",
                                            CreditCardType: "",
                                            LastFour: creditData.LastFour,
                                            Paymenttype: "Credit Card",
                                            PaymentData: {
                                                dataDescriptor: creditData.dataDescriptor,
                                                dataValue: creditData.dataValue,
                                                Address: values.Address,
                                                City: values.City,
                                                FirstName: values.FirstName,
                                                LastName: values.LastName,
                                                State: values.State,
                                                Zip: values.Zip,
                                                BillToEmail: values.BillToEmail,
                                                BillToName: values.FirstName + " " + values.LastName,
                                                BillingCountry: values.BillingCountry,
                                                BillingPhone: values.BillingPhone
                                            }
                                        }
                                    };
                                    credentialRenewal.mutateAsync(credentialRenewalObj).then((res) => {
                                        if (res?.data?.ApiCode === 200 && res.success === true) {
                                            setSubmitting(false)
                                            window.location.href = "/testing-personnel/";
                                        }

                                    }).finally(() => {
                                        setSubmitting(false);
                            
                                    }).catch((err) => {
                                        console.log(err);
                                        setSubmitting(false);
                                        
                                    });
                                }
                                else {
                                    setSubmitting(false);
                                   
                                    return;
                                }
                            }}>
                            {({ values, isSubmitting, handleSubmit, handleChange, handleBlur, handleReset, isValid, dirty }) => (
                                <form
                                    onReset={handleReset}
                                    onSubmit={e => {
                                        if (isSubmitting) {
                                            e.preventDefault();
                                        }
                                        else {
                                            handleSubmit(e);
                                        }
                                    }} className="cco_form">

                                    <div className="cco_row">
                                        <div className="cco_col">
                                            <>
                                                <div className="cco_form_row">
                                                    <div className="cco_form_col">
                                                        <h2 className="cco_form_heading">Billing Information</h2>
                                                        <p style={{paddingBottom:'20px'}} ><strong>Note: We Accept VISA, MasterCard, and American Express.</strong></p>

                                                        <div className="cco_form_wrap">
                                                            <div className="cco_form_row">
                                                                <div className="cco_form_col" style={{ marginBottom: "10px" }}>
                                                                    <div className="cco_form_wrap">
                                                                        <div className="cco_blurb cco_blurb_4" style={{ alignItems: "center" }}>
                                                                            {isCreditInfo && (
                                                                                <><div className="cco_blurb_main"> Credit Card Number</div>
                                                                                    <div className="cco_blurb_aside">{creditData?.CardNumber || ''}</div>

                                                                                </>
                                                                            )}
                                                                            {!isCreditInfo && (
                                                                                <>
                                                                                    <HostedForm
                                                                                        buttonText={'Click here to fill Credit Card Information'}
                                                                                        formButtonText={"Continue"}
                                                                                        containerClassName={'cco_menu_item'}
                                                                                        buttonStyle={{ "backgroundColor": "#c67b05", "color": "#ffffff", "fontFamily": "\"DMSans Bold\"", "fontSize": "18px", "fontWeight": "700", "lineHeight": "1", "textAlign": "center", "display": "inline-flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "maxWidth": "100%", width: "100%", "padding": "10px", "border": "none", opacity: isValidated ? '.5' : '1', cursor: isValidated ? 'not-allowed' : 'pointer' }}
                                                                                        containerStyle={{ "backgroundColor": "#c67b05", "color": "#ffffff", "fontFamily": "\"DMSans Bold\"", "fontSize": "18px", "fontWeight": "700", "lineHeight": "1", "textAlign": "center", "display": "inline-flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "maxWidth": "100%", "minHeight": "42px", "padding": "0px 0px", "border": "2px solid #c67b05", "borderRadius": "21px", cursor: isValidated ? 'not-allowed' : 'pointer' }}
                                                                                        formHeaderText={credentialObj?.myCCOLabel + " Payment"}
                                                                                        authData={{ apiLoginID: AuthData?.ApiLoginID || '', clientKey: AuthData?.ClientKey || '' }}
                                                                                        environment={AuthData?.IsSandbox ? 'SANDBOX' : 'PRODUCTION'}
                                                                                        onSubmit={handleSubmits}
                                                                                        billingAddressOptions={{ show: false, required: false }}
                                                                                        errorTextStyle={{ fontSize: "12px" }}

                                                                                    />
                                                                                    {!isCreditInfo && isSubmitting && (
                                                                                        <p style={{ color: "red", textAlign: "center", padding: "10px" }}>Please fill credit card information!</p>

                                                                                    )}
                                                                                </>

                                                                            )}

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>



                                                            <ul className="cco_form_fieldset">

                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccoFullName">First Name <span style={{ color: '#db3a3a' }}>*</span></label>
                                                                    <Field type="text" id="ccoCity" name="FirstName" />
                                                                    <ErrorMessage name={'FirstName'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                                </li>
                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccoFullName">Last Name <span style={{ color: '#db3a3a' }}>*</span></label>
                                                                    <Field type="text" id="ccoCity" name="LastName" />
                                                                    <ErrorMessage name={'LastName'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                                </li>
                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccoEmail">Email <span style={{ color: '#db3a3a' }}>*</span></label>
                                                                    <Field type="text" id="ccoCity" name="BillToEmail" />
                                                                    <ErrorMessage name={'BillToEmail'} component={'div'} className="cco_form_message cco_form_message_error" />


                                                                </li>
                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccoPhoneBilling">Phone <span style={{ color: '#db3a3a' }}>*</span></label>

                                                                    <InputMask
                                                                        type={'tel'}
                                                                        id={'ccoPhoneBilling'}
                                                                        mask="+1 (999) 999-9999"
                                                                        value={values.BillingPhone}
                                                                        onChange={handleChange('BillingPhone')}
                                                                        onBlur={handleBlur('BillingPhone')}
                                                                    />

                                                                    <ErrorMessage component={'div'} name={'BillingPhone'} className="cco_form_message cco_form_message_error" />
                                                                </li>
                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccoBillingCountry">
                                                                        Country <span style={{ color: '#db3a3a' }}>*</span>
                                                                    </label>
                                                                    <Field type="text" id="ccoBillingCountry" name="BillingCountry" />
                                                                    <ErrorMessage name={'BillingCountry'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                                </li>

                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccoFullName">Address <span style={{ color: '#db3a3a' }}>*</span></label>
                                                                    <Field type="text" id="ccoCity" name="Address" />
                                                                    <ErrorMessage name={'Address'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                                </li>
                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                                    <label htmlFor="ccoCity">
                                                                        City <span style={{ color: '#db3a3a' }}>*</span>
                                                                    </label>
                                                                    <Field type="text" id="ccoCity" name="City" />
                                                                    <ErrorMessage name={'City'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                                </li>

                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                                    <label htmlFor="ccoState">State <span style={{ color: '#db3a3a' }}>*</span></label>
                                                                    <Field type="text" id="ccoCity" name="State" />
                                                                    <ErrorMessage name={'State'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                                </li>
                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                                    <label htmlFor="ccoFullName">Zip <span style={{ color: '#db3a3a' }}>*</span></label>
                                                                    <Field type="text" id="ccoCity" name="Zip" />
                                                                    <ErrorMessage name={'Zip'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                                </li>


                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cco_form_row">
                                                    <div className="cco_form_col">
                                                        {!isSubmitting && (
                                                            <>
                                                                {credentialRenewal.data?.success && (
                                                                    <div
                                                                        className="cco_form_message cco_form_message_success"
                                                                        style={{
                                                                            fontSize: 16,
                                                                            marginBottom: 20
                                                                        }}>
                                                                        <strong>
                                                                            Request submited successfully.
                                                                        </strong>
                                                                    </div>
                                                                )}

                                                                {credentialRenewal.error && (
                                                                    <div
                                                                        className="cco_form_message cco_form_message_error"
                                                                        style={{
                                                                            marginBottom: 20
                                                                        }}>
                                                                        {(credentialRenewal.error as Error).message }
                                                                        
                                                                    </div>
                                                                )}
                                                             
                                                            </>
                                                        )}
                                                        <ul className="cco_menu cco_menu_7">
                                                            <li className="cco_menu_item">
                                                                <button className="cco_form_submit" disabled={!(isValid && dirty) && !isCreditInfo} type={'submit'} style={{ opacity: (isValid && dirty) && isCreditInfo ? '1' : '.5', cursor: (isValid && dirty) && isCreditInfo ? 'pointer' : 'not-allowed' }}>
                                                                    {credentialRenewal.isLoading || credentialRenewal.isSuccess && isCreditInfo ? 'Processing' : 'Pay $' + credentialObj.RenewalFee.Price}
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>


                                            </>

                                        </div>
                                    </div>

                                </form>
                            )}

                        </Formik>

                        </>

                    )}
                    {!checkout &&  (
                        <><div className="cco_row ">
                            <div className="cco_col">
                                <div className="cco_text cco_text_0">
                                    <div className="cco_text_inner">
                                        <strong>{credentialObj?.myCCOLabel || ''} Credential Information</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div className="cco_row">
                                <div className="cco_col">
                                    {credentials.isLoading ? (<Loader />) : credentials.error ? (<p>{credentials.error.message}</p>) : (
                                        <div className="cco_card cco_card_2">

                                            <div className="cco_card_body">
                                            </div>
                                            {/* Credential Description */}
                                            {credentialObj.ProductDetail ? (
                                                <div className="cco_card_footer">
                                                    
                                                    <div className="cco_text">
                                                        <div className="cco_text_inner" dangerouslySetInnerHTML={{ __html: credentialObj?.ProductDetail || '' }}></div>
                                                    </div>
                                                      
                                                </div>
                                            ) : (
                                                <p>Credential information is empty</p>
                                            )}
                                        </div>
                                    )}
                                </div>

                            </div></>
                    )}


                    {!checkout &&  (
                        <>
                            <div className="cco_row cco_row_2">
                                <div className="cco_col">
                                    <div className="cco_text cco_text_0">
                                        <div className="cco_text_inner">
                                            <strong>Agreement</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="cco_row cco_row_9">
                                {credentials.isLoading ? (<Loader />) : credentials.error ? (<p>{credentials.error.message}</p>) :
                                    (
                                        <div className="cco_col">
                                          
                                            <div className="cco_text cco_text_14">
                                                <div className="cco_text_inner" dangerouslySetInnerHTML={{ __html: credentialObj?.ProductDetail || '' }}></div>
                                            </div>
                                        </div>

                                    )}
                            </div>

                            {!credentialRenewal.isLoading && (
                                                            <>
                                                                {credentialRenewal.data?.success && (
                                                                    <div
                                                                        className="cco_form_message cco_form_message_success"
                                                                        style={{
                                                                            fontSize: 16,
                                                                            marginBottom: 20
                                                                        }}>
                                                                        <strong>
                                                                            Request submited successfully.
                                                                        </strong>
                                                                    </div>
                                                                )}

                                                                {credentialRenewal.error && (
                                                                    <div
                                                                        className="cco_form_message cco_form_message_error"
                                                                        style={{
                                                                            marginBottom: 20
                                                                        }}>
                                                                        {(credentialRenewal.error as Error).message}
                                                                    </div>
                                                                )}
                                                                 {credentialObj && !credentialObj.RenewalFee && (
                                                                    <div
                                                                        className="cco_form_message cco_form_message_error"
                                                                        style={{
                                                                            marginBottom: 20
                                                                        }}>
                                                                        Credential product fee not found!
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}

                            {(preRequisiteData?.Status === StatusReason.Approved || preRequisiteData === null || preRequisiteData?.Status === StatusReason.PendingRenewal) &&
                                <div className="cco_form_row">
                                    <div className="cco_form_col">
                                        {credentials.isLoading ? (<Loader />) : credentials.error ? (<p>{credentials.error.message}</p>) :
                                            (
                                                <ul className="cco_menu cco_menu_4" >
                                                    <li className={'cco_menu_item cco_menu_item_checkbox'}>
                                                        <label className={'cco_form_checkbox cco_form_checkbox_0'}>
                                                            <input
                                                            disabled={
                                                                recentExams && !recentExams.length   && credentialObj.RenewalExams && !credentialObj.CertificationDetails.length
                                                               
                                                              }
                                                                name="termsAndConditions"
                                                                id="ccoTermsAndConditions"
                                                                type="checkbox"

                                                                className={'cco_form_checkbox_input'}
                                                                value="termsAndConditions"
                                                                onChange={handleTermsAndConditionsChange} />
                                                            <span className={'cco_form_checkbox_checkmark'} style={{ border: termsAndConditions ? "2px solid #c67b1d" : '1px solid #c67b1d', cursor:
  recentExams && !recentExams.length  &&  credentialObj.RenewalExams &&  !credentialObj.CertificationDetails.length 
                                      ? "not-allowed"
                                      : "pointer"}}></span>
                                                            <p style={{ fontSize: "12px" }}>I understand and agree to this Agreement. I further understand and agree that a payment to register or enroll in any testing personnel training (such as a course or a workshop) cannot be refunded once my request and payment are submitted to CCO. In addition, I understand that any such registration cannot be cancelled or transferred. By submitting a payment, I agree to CCO's <a style={{ color: "#c67b05", padding: "0px", alignItems: "normal" }} href='https://www.nccco.org/nccco/about-nccco/policies/financial-terms-conditions'>Financial Terms and Conditions</a>.</p>
                                                        </label>
                                                    </li>
                                                    
                                                        <li className={`cco_menu_item cco_menu_item_btn ${isValidated ? 'cco_menu_item_btn_disabled' : ''}`}>
                                                        <a 
  href={`javascript:void(0)`} 
  onClick={(event) => { 
    isValidated 
      ? event?.preventDefault()  // Call preventDefault()
      : credentialObj.RenewalFee.Price === 0 
        ? handleZeroFeeCredential() 
        : processedtocheckout(true);
  }}
>
  {credentialObj.RenewalFee.Price === 0 ? 'Submit' : "Proceed to checkout"}
</a>
                                                        </li>
                                                    
                                                </ul>
                                            )}
                                    </div>
                                </div>
                            }
                        </>
                    )}



            

                </>
               </>}
            </div>

            <BlockUI  title='Please wait while payment is processed. Do not refresh page or click back button.' blocking={credentialRenewal.isLoading || credentialRenewal.isSuccess} />

</article>
</div></div>
        </>
    );

};
