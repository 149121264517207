import { useAuthData } from '../../hooks/useAuthData'
import { useLocationProduct } from "../../hooks/useLocationProduct";
import { createcredentialSchema } from "../../schemas/credential";
import { PaymentResponse } from "../../types/paymentauth";
import { LocationProductData } from "../../types/product";
import { Formik, Field, ErrorMessage } from "formik";
import { useState } from "react";
import { HostedForm } from "react-acceptjs";
import InputMask from 'react-input-mask';
import { ILocationRenewal } from "../../types/locationrenewal";
import { useLocationRenewal } from "../../hooks/useLocationRenewal";
import { BlockUI, Loader } from "../../components/Loader";
import { useEot } from "../../hooks/useEot";
import { getUserBasicData } from "../../tools/utils";
import AuthorizeSessionTimer from "../../components/PaymentTimer/AuthorizeSessionTimer";
import { getContactId } from '../../tools/utils';
import { string } from 'yup';
import { useLocation } from 'react-router-dom';

export const LocationRenewal = ({ headerMarginTop }: { headerMarginTop: number }) => {
        console.log('eo :>> ','' );
        const contactId = getContactId()
        const { eotId=null, single=false } = useLocation().state
        const locationId = window.location.pathname.split("/")[2];
        const eotQuery = useEot(eotId);
    const eotData = eotQuery.data?.data.Data;
    const authQuery = useAuthData('location');
    const data = getUserBasicData();
    const AuthData = authQuery.data?.data.Data;

    const productQuery = useLocationProduct(
        true === eotData?.IsPractical
          ? 'Practical Test Site Annual Fee'
          : false === eotData?.IsPractical
          ? 'EOT Annual Test Site Fee'
          : undefined
      );
    const productData = productQuery.data?.data.Data || {} as LocationProductData;

    const locationRenewalMutation = useLocationRenewal();
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [isCreditInfo, setCreditInfo] = useState(false);
    const [creditData, setCreditData] = useState({ LastFour: '', dataDescriptor: '', dataValue: '', CardNumber: '' });

    const initialValues = {
        Address: '',
        BillToEmail: '',
        City: '',
        FirstName: '',
        LastName: '',
        Zip: "",
        State: "",
        BillingPhone: "",
        BillingCountry: ""
    }
    const handleSubmits = (response: PaymentResponse) => {
        if (response.messages.resultCode === 'Ok') {
            setCreditInfo(true);
            // handlerShowTimer(true);

            setCreditData(
                {
                    LastFour: response.encryptedCardData.cardNumber.replaceAll('X', ''),
                    dataDescriptor: response.opaqueData.dataDescriptor,
                    dataValue: response.opaqueData.dataValue,
                    CardNumber: response.encryptedCardData.cardNumber
                }
            );
        }

    };
    const handleTermsAndConditionsChange = (event: any) => {
        setTermsAndConditions(event.target.checked);
    };


    // const isValidated = !termsAndConditions;

    return (

        <div id="main-content" style={{ marginTop: headerMarginTop }}>
        <div className="cco_content_area cco_content_area_0">
        <article className="react-container" >

            <div className="cco_section cco_section_0">
            <div className="cco_row">
        <div className="cco_col">
          <div className="cco_text cco_text_11">
            <div className="cco_text_inner">
              <strong>
                <a href="/testing-personnel">Testing Personnel</a>
              </strong>{' '}
              {'>'}   
              <strong>
                <a href={`${eotQuery.isLoading?'#':eotData?.IsPractical?'/tsc-prac':'/tsc-eot'}`}>{eotQuery.isLoading?'loading...':`TSC ${eotData?.IsPractical?'Practical':'EOT'}`} </a>
              </strong>{' '}
              {'>'} {eotQuery.isLoading?'loading...':eotData?.IsPractical ? 'Practical Location Renewal':'EOT Location Renewal' }
              {isCreditInfo && <AuthorizeSessionTimer startTimer={isCreditInfo} />}
            </div>
          </div>
        </div>
      </div>

      <div className="cco_row cco_row_0">
        {eotQuery.isLoading ? (
            <Loader />
        ):(
            <div className="cco_col cco_col_12">
            <div className="cco_text cco_text_1">
              <div className="cco_text_inner">
                <h1>
                  { eotData?.TestSiteNumber+' '+eotData?.CompanyName  + ' Renewal'}
                  <span className="divider" />
                  <small>
                    CCO ID <strong>{data?.CandidateId}</strong>
                  </small>
                </h1>
              </div>
            </div>
          </div>
        )}
      
      </div>
            {(
                <><Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={createcredentialSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        if (isCreditInfo == true) {
                            const RenewalObj: ILocationRenewal = {
                                ContactId: contactId,
                                LocationId: eotData?.LocationId,
                                ProductId: productData.ProductId,
                                Payment: {
                                    Amount: productData.ProductPrice,
                                    AuthNetTransactionID: "",
                                    CreditCardType: "",
                                    LastFour: creditData.LastFour,
                                    Paymenttype: "Credit Card",
                                    PaymentData: {
                                        dataDescriptor: creditData.dataDescriptor,
                                        dataValue: creditData.dataValue,
                                        Address: values.Address,
                                        City: values.City,
                                        FirstName: values.FirstName,
                                        LastName: values.LastName,
                                        State: values.State,
                                        Zip: values.Zip,
                                        BillToEmail: values.BillToEmail,
                                        BillToName: values.FirstName + " " + values.LastName,
                                        BillingCountry: values.BillingCountry,
                                        BillingPhone: values.BillingPhone
                                    }
                                }
                            };
                            console.log(RenewalObj)
                            locationRenewalMutation.mutateAsync({
                                body: RenewalObj,
                                type: eotData?.IsPractical?'prac':'eot'
                            }).then((res) => {
                                if (res?.data?.ApiCode === 200 && res.success === true) {
                                    setSubmitting(false);

                                    window.location.href =eotData?.IsPractical ? '/tsc-prac':'/tsc-eot/';
                                }

                            }).finally(() => {
                                setSubmitting(false);
                            }).catch((err) => {
                                console.log(err);
                                setSubmitting(false);
                            });
                        }
                        else {
                            setSubmitting(false);
                            return;
                        }
                    }}>
                    {({ values, isSubmitting, handleSubmit, handleChange, handleBlur, handleReset, isValid, dirty }) => (
                        <form
                            onReset={handleReset}
                            onSubmit={e => {
                                if (isSubmitting) {
                                    e.preventDefault();
                                }
                                else {
                                    handleSubmit(e);
                                }
                            }} className="cco_form">

                            <div className="cco_row">
                                {eotQuery.isLoading ?(
                                    <Loader/>
                                ):(
                                    <div className="cco_col">
                                    <>
                                        <div className="cco_row cco_row_7">
                                            <div className="cco_col cco_col_6">
                                                <div className="cco_text cco_text_10">
                                                    <div className="cco_text_inner">
                                                        <h2>Billing Information</h2>
                                                        <p style={{paddingBottom:'20px'}}><strong>Note: We Accept VISA, MasterCard, and American Express.</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cco_form_row">
                                            <div className="cco_form_col">
                                                <div className="cco_form_wrap">
                                                    <div className="cco_form_row">
                                                        <div className="cco_form_col" style={{ marginBottom: "10px" }}>
                                                            <div className="cco_form_wrap">
                                                                <div className="cco_blurb cco_blurb_4" style={{ alignItems: "center" }}>
                                                                    {isCreditInfo && (
                                                                        <><div className="cco_blurb_main"> Credit Card Number</div>
                                                                            <div className="cco_blurb_aside">{creditData?.CardNumber || ''}</div>

                                                                        </>
                                                                    )}
                                                                    {!isCreditInfo && (
                                                                        <>
                                                                            <HostedForm
                                                                                buttonText={'Click here to fill Credit Card Information'}
                                                                                formButtonText={"Continue"}
                                                                                containerClassName={'cco_menu_item'}
                                                                                buttonStyle={{ "backgroundColor": "#c67b05", "color": "#ffffff", "fontFamily": "\"DMSans Bold\"", "fontSize": "18px", "fontWeight": "700", "lineHeight": "1", "textAlign": "center", "display": "inline-flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "maxWidth": "100%", width: "100%", "padding": "10px", "border": "none" }}
                                                                                containerStyle={{ "backgroundColor": "#c67b05", "color": "#ffffff", "fontFamily": "\"DMSans Bold\"", "fontSize": "18px", "fontWeight": "700", "lineHeight": "1", "textAlign": "center", "display": "inline-flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", "maxWidth": "100%", "minHeight": "42px", "padding": "0px 0px", "border": "2px solid #c67b05", "borderRadius": "21px" }}
                                                                                formHeaderText={'Location Renewal' + " Payment"}
                                                                                authData={{ apiLoginID: AuthData?.ApiLoginID || '', clientKey: AuthData?.ClientKey || '' }}
                                                                                environment={AuthData?.IsSandbox ? 'SANDBOX' : 'PRODUCTION'}
                                                                                onSubmit={handleSubmits}
                                                                                billingAddressOptions={{ show: false, required: false }}
                                                                                errorTextStyle={{ fontSize: "12px" }} />
                                                                            {!isCreditInfo && isSubmitting && (
                                                                                <p style={{ color: "red", textAlign: "center", padding: "10px" }}>Please fill credit card information!</p>

                                                                            )}
                                                                        </>

                                                                    )}

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>



                                                    <ul className="cco_form_fieldset">

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoFullName">First Name <span style={{ color: '#db3a3a' }}>*</span></label>
                                                            <Field type="text" id="ccoCity" name="FirstName" />
                                                            <ErrorMessage name={'FirstName'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                        </li>
                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoFullName">Last Name <span style={{ color: '#db3a3a' }}>*</span></label>
                                                            <Field type="text" id="ccoCity" name="LastName" />
                                                            <ErrorMessage name={'LastName'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                        </li>
                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoEmail">Email <span style={{ color: '#db3a3a' }}>*</span></label>
                                                            <Field type="text" id="ccoCity" name="BillToEmail" />
                                                            <ErrorMessage name={'BillToEmail'} component={'div'} className="cco_form_message cco_form_message_error" />


                                                        </li>
                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoPhoneBilling">Phone <span style={{ color: '#db3a3a' }}>*</span></label>

                                                            <InputMask
                                                                type={'tel'}
                                                                id={'ccoPhoneBilling'}
                                                                mask="+1 (999) 999-9999"
                                                                value={values.BillingPhone}
                                                                onChange={handleChange('BillingPhone')}
                                                                onBlur={handleBlur('BillingPhone')} />

                                                            <ErrorMessage component={'div'} name={'BillingPhone'} className="cco_form_message cco_form_message_error" />
                                                        </li>
                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoBillingCountry">
                                                                Country <span style={{ color: '#db3a3a' }}>*</span>
                                                            </label>
                                                            <Field type="text" id="ccoBillingCountry" name="BillingCountry" />
                                                            <ErrorMessage name={'BillingCountry'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoFullName">Address <span style={{ color: '#db3a3a' }}>*</span></label>
                                                            <Field type="text" id="ccoCity" name="Address" />
                                                            <ErrorMessage name={'Address'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                        </li>
                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoCity">
                                                                City <span style={{ color: '#db3a3a' }}>*</span>
                                                            </label>
                                                            <Field type="text" id="ccoCity" name="City" />
                                                            <ErrorMessage name={'City'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoState">State <span style={{ color: '#db3a3a' }}>*</span></label>
                                                            <Field type="text" id="ccoCity" name="State" />
                                                            <ErrorMessage name={'State'} component={'div'} className="cco_form_message cco_form_message_error" />

                                                        </li>
                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoFullName">Zip <span style={{ color: '#db3a3a' }}>*</span></label>
                                                            <Field type="text" id="ccoCity" name="Zip" />
                                                            <ErrorMessage name={'Zip'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                        </li>


                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cco_form_row">
                                            <div className="cco_form_col">
                                                {!isSubmitting && (
                                                    <>
                                                        {locationRenewalMutation.data?.success && (
                                                            <div
                                                                className="cco_form_message cco_form_message_success"
                                                                style={{
                                                                    fontSize: 16,
                                                                    marginBottom: 20
                                                                }}>
                                                                <strong>
                                                                    Request submited successfully.
                                                                </strong>
                                                            </div>
                                                        )}

                                                        {locationRenewalMutation.error && (
                                                            <div
                                                                className="cco_form_message cco_form_message_error"
                                                                style={{
                                                                    marginBottom: 20
                                                                }}>
                                                                {(locationRenewalMutation.error as Error).message}
                                                            </div>
                                                        )}
                                                    </>
                                                )}

                                                {/* <ul className="cco_menu cco_menu_7">
                                                    <li className="cco_menu_item">
                                                        <button className="cco_form_submit" disabled={!(isValid && dirty) && !isCreditInfo} type={'submit'} style={{ opacity: (isValid && dirty) && isCreditInfo ? '1' : '.5', cursor: (isValid && dirty) && isCreditInfo ? 'pointer' : 'not-allowed' }}>
                                                            {cCredentials.isLoading || cCredentials.isSuccess && isCreditInfo ? 'Processing' : 'Pay $' + credentialObj.CredentialFee.Price}
                                                        </button>
                                                    </li>
                                                </ul> */}
                                                <div className="cco_form_row">
                                                    <div className="cco_form_col">

                                                        <ul className="cco_menu cco_menu_4">
                                                            <li className={'cco_menu_item cco_menu_item_checkbox'}>
                                                                <label className={'cco_form_checkbox cco_form_checkbox_0'}>
                                                                    <input
                                                                        name="termsAndConditions"
                                                                        id="ccoTermsAndConditions"
                                                                        type="checkbox"

                                                                        className={'cco_form_checkbox_input'}
                                                                        value="termsAndConditions"
                                                                        onChange={handleTermsAndConditionsChange} />
                                                                    <span className={'cco_form_checkbox_checkmark'} style={{ border: termsAndConditions ? "2px solid #c67b1d" : '1px solid #c67b1d' }}></span>
                                                                    <p style={{ fontSize: "12px" }}>I understand and agree that an application to renew a test site cannot be cancelled or transferred, and payments for application fees cannot be refunded, once the application and payment are submitted to CCO. I also declare that the information contained in this application, as well as the information in any required accompanying documentation, is true and correct. I understand that insufficient or inadequate information may result in delays in processing. By submitting a payment, I agree to CCO's <a style={{ color: "#c67b05", padding: "0px", alignItems: "normal" }} href='https://www.nccco.org/nccco/about-nccco/policies/financial-terms-conditions'>Financial Terms and Conditions</a>.</p>
                                                                </label>
                                                            </li>

                                                            <li className={`cco_menu_item cco_menu_item_btn ${!termsAndConditions ? 'cco_menu_item_btn_disabled' : ''}`}
                                                            >
                                                                {/* <a href={(!termsAndConditions) ? `javascript:void(0)` : `javascript:void(0)`} onClick={() => { isValidated ? event?.preventDefault : event?.preventDefault }}>{`Pay $${productData.ProductPrice} & Submit`}</a> */}
                                                                <button className="cco_form_submit" disabled={!termsAndConditions} type={'submit'} style={{ opacity: (isValid && dirty) && (isCreditInfo && termsAndConditions) ? '1' : '.5', cursor: (isValid && dirty) && (isCreditInfo && termsAndConditions) ? 'pointer' : 'not-allowed' }}>
                                                                    {locationRenewalMutation.isLoading || locationRenewalMutation.isSuccess && isCreditInfo ? 'Processing' : 'Renewal Now $' + productData.ProductPrice}
                                                                </button>
                                                            </li>

                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </>

                                </div>
                                )}
                              
                            </div>

                        </form>
                    )}

                </Formik>

                </>

            )}

            </div>
            <BlockUI title='Please wait while payment is processed. Do not refresh page or click back button.' blocking={locationRenewalMutation.isLoading || locationRenewalMutation.isSuccess} />
        </article></div></div>
    );

};
