import { useTranslation } from "react-i18next";
import { CredentialsAccordion } from "../../components/Accordion/CredentialsAccordion";
import { Loader } from "../../components/Loader";
import { useCredentials } from "../../hooks/useCredentials";

export const AvailableCredentials = ({ headerMarginTop }: { headerMarginTop: number }) => {
    const credentials = useCredentials();
    const userCredentials = credentials?.data?.data?.Data;
    const { t} = useTranslation();
    const availableCredentials = userCredentials?.AvailableCredentials.sort((a, b) => a.CredentialOrder - b.CredentialOrder).map((credential, index: number) => {
      if (!credential) {
        return null;
      }
  
      return (
        <div className="cco_group_item" key={index}>
                <CredentialsAccordion credential={credential}/>
        </div>
      );
    }).filter(Boolean);
    return (
        <div id="main-content" style={{ marginTop: headerMarginTop }}>
    <div className="cco_content_area cco_content_area_0">
    <article className="react-container" >
      <div className="cco_section cco_section_0">
        <div className="cco_row">
          <div className="cco_col">
            <div className="cco_row cco_row_8">
              <div className="cco_col">
                <div className="cco_text cco_text_11">
                  <div className="cco_text_inner">
                    <strong>
                      <a href="/testing-personnel">{t('AvaliableCredential.pageHeading')}</a>
                    </strong>{' '}
                    &gt; {t('AvaliableCredential.AvailableCredentials')}
                  </div>
                </div>
              </div>
            </div>
  
  
  
  
            <div className="cco_row cco_row_3">
              <div className="cco_col">
                {credentials.isLoading ? (
                  <Loader />
                ) : credentials.error ? (
                  <p>{credentials.error.message}</p>
                ) : availableCredentials && availableCredentials.length > 0 ? (
                  <div className="cco_group cco_group_2">
                    {availableCredentials}
                  </div>
                ) : (
                  <p>{t('AvaliableCredential.NoCredentials')}</p>
                )}
              </div>
            </div>
          </div>
          </div>
          </div>
          </article>
          </div>
          </div>
          );
  };