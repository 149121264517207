import { useTranslation } from 'react-i18next';
import { StatusReason, showContactCredentialCardBorder } from '../../tools/utils';
import { PreRequisiteData } from '../../types/prerequisite';
import { useEffect, useState } from 'react';


interface IContactCredentialCardProps {
    index: number | null;
    credential: PreRequisiteData;
}

export const PreReqCredentialCard = ({credential,index}: IContactCredentialCardProps) => {

    const [showcardBorder, setShowcardBorder] = useState(false);
    const {t}=useTranslation();
    useEffect(() => {
        if (credential.ExpireDate != '0001-01-01T00:00:00' && credential.Status===StatusReason.Approved || credential.Status===StatusReason.PendingRenewal) {
            showContactCredentialCardBorder(credential.ExpireDate, setShowcardBorder);
        }
    }, [credential.ExpireDate]);
    
    return (
        <>
           {credential.Children && credential.Children.length > 0 &&
                credential.Children.map((child, i) => (
                    <PreReqCredentialCard
                        credential={child}
                        index={i}
                        key={child.PreReqCredentialId + i}
                    />
                ))
            }
        <div className="cco_group_item" key={credential.PreReqCredentialId+index} style={{ marginTop: '10px' }}>
            <a href={(credential.ContactCredentialId === null || credential.Status===StatusReason.Expired) && credential.PreReqCredentialId ? `/credential/${credential.PreReqCredentialId}` : `/my-credential/${credential.ContactCredentialId}`} style={{ color: '#666' }}>

                <div className={`cco_card cco_card_myCredential ${credential.Status === StatusReason.Approved ? 'purchased-card' : (credential.Status === StatusReason.Pending || credential.Status === StatusReason.PendingRenewal)  ? 'pending-card' : 'notcompleted-card'}`}
                style={{ border:showcardBorder?'3px red solid':''}}
                >
                    <div className="cco_card_body">
                        <div

                            className="cco_card_main"
                            style={{ width: "100%", padding: "10px" }}
                        >
                            <div className="cco_text cco_text_myCredential">
                                <div className="cco_text_inner">
                                    <h3> <strong>{credential?.PreReqCredentialLabel}</strong></h3>
                                    <div>
                                    {t("Credential.PreReq.PreReqStatus")}{credential.Status ||  t("Credential.PreReq.NotPurchased")}
                                    </div>
                                    <div>
                                    {t("Credential.PreReq.PreReqExpirationDate")}{' '}
                                    {credential?.ExpireDate?.split(
                                        'T'
                                    )[0] === '0001-01-01'
                                        ? 'Not Available'
                                        : credential?.ExpireDate?.split(
                                              'T'
                                          )[0]}
                                </div>
                                    {/* <hr style={{color:'#ffffff'}} /> */}

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </a>

        </div>
        {/* <ul className="cco_menu cco_menu_5">
            <li className="cco_menu_item desktop-arrow">
                <a className="" href="#" style={{ color: '#c67b05', cursor: 'auto' }}></a>
            </li>
            <li className="cco_menu_item mobile-arrow">
                <a className="" href="#" style={{ transform: 'rotate(90deg)', color: '#c67b05', cursor: 'auto' }}></a>
            </li>

            

        </ul> */}
        <div className="desktop-arrow"></div>

        <div className="mobile-arrow"></div>

    </>
    );
};
