import { Formik, Field, ErrorMessage } from 'formik';
import { useState } from 'react';

import { IGeneratePDF } from '../../types/generatepdf';
import { useGeneratePDF } from '../../hooks/useGeneratePDF';
import { getUserBasicData } from '../../tools/utils';
import { generatepdf } from '../../schemas/generatepdf';
import moment from 'moment';
import { Required } from '../../components/Required/Required';
import { BlockUI, Loader } from '../../components/Loader';
import { GeneratePDFAccordion } from '../../components/Accordion/GeneratePDFAccordion';

import { useCredentials } from '../../hooks/useCredentials';
import { useTranslation } from 'react-i18next';




export const GeneratePDF = ({ headerMarginTop }: { headerMarginTop: number }) => {
    const { t} = useTranslation();
    const generatePDFMutation = useGeneratePDF();
    const myCredentialsQuery = useCredentials();
    const userCredentials = myCredentialsQuery?.data?.data?.Data;
    const proctorCredential = userCredentials?.MyCredentials.find(
        x => x.EarnedCredential.Name === 'Proctor-Practical' && (x.EarnedCredential.StatusReason === 'Approved' || x.EarnedCredential.StatusReason==="PendingRenewal")
    );
    const data = getUserBasicData();
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [documentType, setDocumentType] = useState('');

    const initialValues = {
        qty: 'default',
        examtype: 'default',
    };

    const initialValuesSGP = {
        qty: '1',
        examtype: '1',
    };

    const handleTermsAndConditionsChange = (event: any) => {
        setTermsAndConditions(event.target.checked);
    };

    const handleDocumentType = (type:string) => {
        setDocumentType(type);
    };

    const generateType = [
        {
            name: t("GeneratePdf.Sheet.sheet1"),
            id: 1,
            type: 'scoresheet',
        },
        {
            name: t("GeneratePdf.Sheet.sheet2"),
            id: 2,
            type: 'singleperson',
        },
        {
            name: t("GeneratePdf.Sheet.sheet3"),
            id: 3,
            type: 'CRG',
        },
    ];

    // const isValidated = !termsAndConditions;

    return (
        <div id="main-content" style={{ marginTop: headerMarginTop }}>
        <div className="cco_content_area cco_content_area_0">
        <article className="react-container" >
            <div className="cco_section cco_section_0">
                <div className="cco_row">
                    <div className="cco_col">
                        <div className="cco_row cco_row_8">
                            <div className="cco_col">
                                <div className="cco_text cco_text_11">
                                    <div className="cco_text_inner">
                                        <strong>
                                            <a href="/testing-personnel">Testing Personnel</a>
                                        </strong>{' '}
                                        &gt; {'Generate PDFs'}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <>

                        <div className="cco_row" style={{ marginBottom: '20px' }}>
                                <div className="cco_col">
                                    {myCredentialsQuery.isLoading ? (
                                        <Loader />
                                    ) : myCredentialsQuery.error ? (
                                        <p>{myCredentialsQuery.error.message}</p>
                                    ) : !proctorCredential ? (
                                        <div className="cco_text cco_text_12">
                                            <div className="cco_text_inner">
                                                <p>
                                                    Our records indicate that you are not authorized to access this
                                                    page. Please contact CCO if you have any questions.{' '}
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {proctorCredential && (
                                <>
                                    {documentType === '' ? (
                                        <div className="cco_row cco_row_3">
                                            <div className="cco_col">
                                                {generateType.length > 0 ? (
                                                    <div className="cco_group cco_group_1">
                                                        {generateType.map((item, index: number) => (
                                                            <div className="cco_group_item" key={index}>
                                                                <GeneratePDFAccordion
                                                                    name={item.name}
                                                                    setDocumentType={handleDocumentType}
                                                                    type={item.type}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <p>No Credentials available</p>
                                                )}
                                            </div>
                                        </div>
                                    ) : documentType === 'scoresheet' ? (
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={initialValues}
                                            validationSchema={generatepdf}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setisLoading(true);
                                                const GenerateObj: IGeneratePDF = {
                                                    firstname: data.First,
                                                    lastname: data.Last,
                                                    // accreditationnumber: 34523,
                                                    examtype: values.examtype,
                                                    qty: values.qty,
                                                    ccoid: data.CandidateId,
                                                };
                                                console.log(GenerateObj);
                                                console.log(values);
                                                generatePDFMutation
                                                    .mutateAsync({
                                                        body: GenerateObj,
                                                        type: documentType,
                                                    })
                                                    .then(async res => {
                                                        if (res.success && res.status === 200) {
                                                            const blob = await res.data.blob();
                                                            const todayDate = moment().format('MMDDYY');

                                                            // Create a download link
                                                            const downloadLink = document.createElement('a');
                                                            downloadLink.href = URL.createObjectURL(blob);
                                                            downloadLink.download = `CCO_${values.examtype}_${todayDate}.pdf`;
                                                            // Append the link to the body
                                                            document.body.appendChild(downloadLink);

                                                            // Trigger a click on the link to start the download
                                                            downloadLink.click();

                                                            // Remove the link from the body
                                                            document.body.removeChild(downloadLink);
                                                            setSubmitting(false);

                                                            setisLoading(false);

                                                            setTimeout(() => {});
                                                        }

                                                        // if (res?.data?.ApiCode === 200 && res.success === true) {

                                                        // }
                                                    })
                                                    .finally(() => {
                                                        setSubmitting(false);
                                                        setisLoading(false);
                                                    })
                                                    .catch(err => {
                                                        console.log(err);
                                                        setSubmitting(false);
                                                        setisLoading(false);
                                                    });
                                            }}
                                        >
                                            {({ isSubmitting, handleSubmit, handleReset, isValid, dirty }) => (
                                                <form
                                                    onReset={handleReset}
                                                    onSubmit={e => {
                                                        if (isSubmitting) {
                                                            e.preventDefault();
                                                        } else {
                                                            handleSubmit(e);
                                                        }
                                                    }}
                                                    className="cco_form"
                                                >
                                                    <div className="cco_row">
                                                        <div className="cco_col">
                                                            <>
                                                                <div className="cco_row cco_row_7">
                                                                    <div className="cco_col cco_col_6">
                                                                        <div className="cco_text cco_text_10">
                                                                            <div className="cco_text_inner">
                                                                                <h2 className="cco_cco_heading">
                                                                                    {t("GeneratePdf.Headerpage1.practicalExamScoreSheetDownload")}
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="cco_col cco_col_7">
                                            <div className="cco_btn cco_btn_3">
                                                <a href={'#'} onClick={handler}>Back to Test Site</a>
                                            </div>
                                        </div> */}
                                                                </div>

                                                                <div className="cco_row">
                                                                    <div className="cco_col_12">
                                                                        <h6>
                                                                        {t("GeneratePdf.Headerpage1.termsAndConditions")}
                                                                        </h6>

                                                                        <ul style={{ listStyleType: 'disc' }}>
                                                                            <li>
                                                                            ▪️{t("GeneratePdf.Headerpage1.credentialProctor")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage1.scoreSheetRestrictions")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage1.approved")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage1.scoreSheetUsage")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage1.expirationDetails")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️  {t("GeneratePdf.Headerpage1.destructionPolicy")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage1.copyrightProtection")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️{t("GeneratePdf.Headerpage1.sharingRestriction")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage1.secureStorageRequirement")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage1.deletionRequirement")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage1.consequenceAwareness")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage1.sanctionAvoidance")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️  {t("GeneratePdf.Headerpage1.examAdministration")}
                                                                            </li>

                                                                            <p className="ccolinks pt-3">
                                                                                {t("GeneratePdf.Headerpage1.printingRecommendation")}
                                                                            </p>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="cco_form_row">
                                                                    <div className="cco_form_col_1">
                                                                        <div className="cco_form">
                                                                            <div className="cco_form_row">
                                                                                <div className="cco_form_col">
                                                                                    <ul className="cco_menu cco_menu_8 ">
                                                                                        <li
                                                                                            className={
                                                                                                'cco_menu_item cco_menu_item_checkbox'
                                                                                            }
                                                                                        >
                                                                                            <label
                                                                                                className={
                                                                                                    'cco_form_checkbox cco_form_checkbox_0'
                                                                                                }
                                                                                            >
                                                                                                <input
                                                                                                    name="termsAndConditions"
                                                                                                    id="ccoTermsAndConditions"
                                                                                                    type="checkbox"
                                                                                                    className={
                                                                                                        'cco_form_checkbox_input'
                                                                                                    }
                                                                                                    value="termsAndConditions"
                                                                                                    onChange={
                                                                                                        handleTermsAndConditionsChange
                                                                                                    }
                                                                                                />
                                                                                                <span
                                                                                                    className={
                                                                                                        'cco_form_checkbox_checkmark'
                                                                                                    }
                                                                                                    style={{
                                                                                                        border: termsAndConditions
                                                                                                            ? '2px solid #c67b1d'
                                                                                                            : '1px solid #c67b1d',
                                                                                                    }}
                                                                                                ></span>
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize:
                                                                                                            '12px',
                                                                                                    }}
                                                                                                >
                                                                                                    {t("GeneratePdf.Headerpage1.acknowledgment")}
                                                                                                </p>
                                                                                            </label>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <ul className="cco_form_fieldset">
                                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_large">
                                                                                    <label htmlFor="ccoisBoomTruck">
                                                                                        {t("GeneratePdf.Headerpage1.label")} <Required />
                                                                                    </label>
                                                                                    <Field
                                                                                        as="select"
                                                                                        name="examtype"
                                                                                        id="ccoexamtype"
                                                                                    >
                                                                                        <option
                                                                                            value="default"
                                                                                            disabled={true}
                                                                                        >
                                                                                            {t("GeneratePdf.Headerpage1.dropdownPlaceholder1")}
                                                                                        </option>

                                                                                        <option value="AMP">
                                                                                            {t("GeneratePdf.Headerpage1.dropdown1")}
                                                                                        </option>
                                                                                        <option value="ABC">
                                                                                            {t("GeneratePdf.Headerpage1.dropdown2")}
                                                                                        </option>
                                                                                        <option value="ABL">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown3")}
                                                                                        </option>
                                                                                        <option value="CPO1">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown4")}
                                                                                        </option>
                                                                                        <option value="CPO2">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown5")}
                                                                                        </option>
                                                                                        <option value="DPD">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown6")}
                                                                                        </option>
                                                                                        <option value="DDO">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown7")}
                                                                                        </option>
                                                                                        <option value="FDR">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown8")}
                                                                                        </option>
                                                                                        <option value="LAT">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown9")}
                                                                                        </option>
                                                                                        <option value="TLL">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown10")}
                                                                                        </option>
                                                                                        <option value="TSS">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown11")}
                                                                                        </option>
                                                                                        <option value="OVR">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown12")}
                                                                                        </option>
                                                                                        <option value="RIG1">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown13")}
                                                                                        </option>
                                                                                        <option value="RIG2">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown14")}
                                                                                        </option>
                                                                                        <option value="STC">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown15")}
                                                                                        </option>
                                                                                        <option value="SGP">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown16")}
                                                                                        </option>
                                                                                        <option value="THF">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown17")}
                                                                                        </option>
                                                                                        <option value="THR">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown18")}
                                                                                        </option>
                                                                                        <option value="TWR">
                                                                                        {t("GeneratePdf.Headerpage1.dropdown19")}
                                                                                        </option>
                                                                                    </Field>
                                                                                    <ErrorMessage
                                                                                        component={'div'}
                                                                                        name={'examtype'}
                                                                                        className="cco_form_message cco_form_message_error"
                                                                                    />
                                                                                </li>
                                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_large">
                                                                                    <label htmlFor="ccoisBoomTruck">
                                                                                         {t("GeneratePdf.Headerpage1.label2")} <Required />
                                                                                    </label>
                                                                                    <Field
                                                                                        as="select"
                                                                                        name="qty"
                                                                                        id="ccoquantity"
                                                                                    >
                                                                                        <option
                                                                                            value="default"
                                                                                            disabled={true}
                                                                                        >
                                                                                            {t("GeneratePdf.Headerpage1.dropdownPlaceholder")}
                                                                                        </option>
                                                                                        <option value="2">2</option>
                                                                                        <option value="5">5</option>
                                                                                        <option value="10">10</option>
                                                                                        <option value="20">20</option>
                                                                                    </Field>
                                                                                    <ErrorMessage
                                                                                        component={'div'}
                                                                                        name={'qty'}
                                                                                        className="cco_form_message cco_form_message_error"
                                                                                    />
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="cco_form_row">
                                                                    <div className="cco_form_col">
                                                                        {!isSubmitting && (
                                                                            <>
                                                                                {generatePDFMutation.data?.success && (
                                                                                    <div
                                                                                        className="cco_form_message cco_form_message_success"
                                                                                        style={{
                                                                                            fontSize: 16,
                                                                                            marginBottom: 20,
                                                                                        }}
                                                                                    >
                                                                                        <strong>
                                                                                            Generated PDF successfully.
                                                                                        </strong>
                                                                                    </div>
                                                                                )}

                                                                                {generatePDFMutation.error && (
                                                                                    <div
                                                                                        className="cco_form_message cco_form_message_error"
                                                                                        style={{
                                                                                            marginBottom: 20,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            (
                                                                                                generatePDFMutation.error as Error
                                                                                            ).message
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        <div className="cco_form_row">
                                                                            <div className="cco_form_col">
                                                                                <ul className="cco_menu cco_menu_2 ">
                                                                                    <li
                                                                                        className={`cco_menu_item cco_menu_item_btn   ${
                                                                                            !termsAndConditions
                                                                                                ? 'cco_menu_item_btn_disabled'
                                                                                                : ''
                                                                                        }`}
                                                                                    >
                                                                                        {/* <a href={(!termsAndConditions) ? `javascript:void(0)` : `javascript:void(0)`} onClick={() => { isValidated ? event?.preventDefault : event?.preventDefault }}>{`Pay $${productData.ProductPrice} & Submit`}</a> */}

                                                                                        <button
                                                                                            className="cco_form_submit"
                                                                                            disabled={
                                                                                                !termsAndConditions
                                                                                            }
                                                                                            type={'submit'}
                                                                                            style={{
                                                                                                opacity:
                                                                                                    isValid &&
                                                                                                    dirty &&
                                                                                                    termsAndConditions
                                                                                                        ? '1'
                                                                                                        : '.5',
                                                                                                cursor:
                                                                                                    isValid &&
                                                                                                    dirty &&
                                                                                                    termsAndConditions
                                                                                                        ? 'pointer'
                                                                                                        : 'not-allowed',
                                                                                                fontSize: '16px',
                                                                                            }}
                                                                                        >
                                                                                            {isLoading
                                                                                                ? 'Generating PDF'
                                                                                                :t("GeneratePdf.Sheet.sheet1")
                                                                                                }
                                                                                        </button>
                                                                                    </li>
                                                                                    <li
                                                                                        className={`cco_menu_item cco_menu_item_btn`}
                                                                                    >
                                                                                        <button
                                                                                            className="cco_form_submit"
                                                                                            onClick={() => {
                                                                                                setDocumentType('');
                                                                                                generatePDFMutation.reset();
                                                                                            }}
                                                                                            type={'button'}
                                                                                            style={{ fontSize: '16px' }}
                                                                                        >
                                                                                            {t("GeneratePdf.Headerpage1.back")}
                                                                                        </button>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    ) : (
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={initialValuesSGP}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setisLoading(true);
                                                const GenerateObj: IGeneratePDF = {
                                                    firstname: data.First,
                                                    lastname: data.Last,
                                                    // accreditationnumber: 34523,
                                                    ccoid: data.CandidateId,
                                                };
                                                console.log(GenerateObj);
                                                console.log(values);
                                                generatePDFMutation
                                                    .mutateAsync({
                                                        body: GenerateObj,
                                                        type: documentType,
                                                    })
                                                    .then(async res => {
                                                        if (res.success && res.status === 200) {
                                                            const blob = await res.data.blob();
                                                            const todayDate = moment().format('MMDDYY');

                                                            // Create a download link
                                                            const downloadLink = document.createElement('a');
                                                            downloadLink.href = URL.createObjectURL(blob);
                                                            if (documentType === 'CRG') {
                                                                downloadLink.download = `CCO_CRG_${todayDate}.pdf`;
                                                            } else {
                                                                downloadLink.download = `CCO_${todayDate}.pdf`;
                                                            }
                                                            // Append the link to the body
                                                            document.body.appendChild(downloadLink);

                                                            // Trigger a click on the link to start the download
                                                            downloadLink.click();

                                                            // Remove the link from the body
                                                            document.body.removeChild(downloadLink);
                                                            setSubmitting(false);

                                                            setisLoading(false);

                                                            setTimeout(() => {});
                                                        }

                                                        // if (res?.data?.ApiCode === 200 && res.success === true) {

                                                        // }
                                                    })
                                                    .finally(() => {
                                                        setSubmitting(false);
                                                        setisLoading(false);
                                                    })
                                                    .catch(err => {
                                                        console.log(err);
                                                        setSubmitting(false);
                                                        setisLoading(false);
                                                    });
                                            }}
                                        >
                                            {({ isSubmitting, handleSubmit, handleReset }) => (
                                                <form
                                                    onReset={handleReset}
                                                    onSubmit={e => {
                                                        if (isSubmitting) {
                                                            e.preventDefault();
                                                        } else {
                                                            handleSubmit(e);
                                                        }
                                                    }}
                                                    className="cco_form"
                                                >
                                                    <div className="cco_row">
                                                        <div className="cco_col">
                                                            <>
                                                                <div className="cco_row cco_row_7">
                                                                    <div className="cco_col cco_col_6">
                                                                        <div className="cco_text cco_text_10">
                                                                            <div className="cco_text_inner">
                                                                            <h2 className="cco_cco_heading">
                                                                                    {t("GeneratePdf.Headerpage2.signalPerson")}
                                                                                </h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="cco_col cco_col_7">
                                            <div className="cco_btn cco_btn_3">
                                                <a href={'#'} onClick={handler}>Back to Test Site</a>
                                            </div>
                                        </div> */}
                                                                </div>

                                                                <div className="cco_row">
                                                                    <div className="cco_col_12">
                                                                        <h6>
                                                                        {t("GeneratePdf.Headerpage2.agreement")}
                                                                        </h6>

                                                                        <ul style={{ listStyleType: 'disc' }}>
                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage2.credentialPracticalExam")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage2.approvalToAdminister")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage2.responseGuide")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️  {t("GeneratePdf.Headerpage2.shareLink")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️  {t("GeneratePdf.Headerpage2.CandidateResponse")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage2.copiesOfExam")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage2.consequenses")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage2.practicalExamProtor")}
                                                                            </li>

                                                                            <li>
                                                                            ▪️ {t("GeneratePdf.Headerpage2.CCOPoliceandProcedure")}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="cco_form_row">
                                                                    <div className="cco_form_col_1">
                                                                        <div className="cco_form">
                                                                            <div className="cco_form_row">
                                                                                <div className="cco_form_col">
                                                                                    <ul className="cco_menu cco_menu_8 ">
                                                                                        <li
                                                                                            className={
                                                                                                'cco_menu_item cco_menu_item_checkbox'
                                                                                            }
                                                                                        >
                                                                                            <label
                                                                                                className={
                                                                                                    'cco_form_checkbox cco_form_checkbox_0'
                                                                                                }
                                                                                            >
                                                                                                <input
                                                                                                    name="termsAndConditions"
                                                                                                    id="ccoTermsAndConditions"
                                                                                                    type="checkbox"
                                                                                                    className={
                                                                                                        'cco_form_checkbox_input'
                                                                                                    }
                                                                                                    value="termsAndConditions"
                                                                                                    onChange={
                                                                                                        handleTermsAndConditionsChange
                                                                                                    }
                                                                                                />
                                                                                                <span
                                                                                                    className={
                                                                                                        'cco_form_checkbox_checkmark'
                                                                                                    }
                                                                                                    style={{
                                                                                                        border: termsAndConditions
                                                                                                            ? '2px solid #c67b1d'
                                                                                                            : '1px solid #c67b1d',
                                                                                                    }}
                                                                                                ></span>
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontSize:
                                                                                                            '12px',
                                                                                                    }}
                                                                                                >
                                                                                                    {t("GeneratePdf.Headerpage2.acknowledgment")}.
                                                                                                </p>
                                                                                            </label>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="cco_form_row">
                                                                    <div className="cco_form_col">
                                                                        {!isSubmitting && (
                                                                            <>
                                                                                {generatePDFMutation.data?.success && (
                                                                                    <div
                                                                                        className="cco_form_message cco_form_message_success"
                                                                                        style={{
                                                                                            fontSize: 16,
                                                                                            marginBottom: 20,
                                                                                        }}
                                                                                    >
                                                                                        <strong>
                                                                                            Generated PDF successfully.
                                                                                        </strong>
                                                                                    </div>
                                                                                )}

                                                                                {generatePDFMutation.error && (
                                                                                    <div
                                                                                        className="cco_form_message cco_form_message_error"
                                                                                        style={{
                                                                                            marginBottom: 20,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            (
                                                                                                generatePDFMutation.error as Error
                                                                                            ).message
                                                                                        }
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                        <div className="cco_form_row">
                                                                            <div className="cco_form_col">
                                                                                <ul className="cco_menu cco_menu_2 ">
                                                                                    <li
                                                                                        className={`cco_menu_item cco_menu_item_btn   ${
                                                                                            !termsAndConditions
                                                                                                ? 'cco_menu_item_btn_disabled'
                                                                                                : ''
                                                                                        }`}
                                                                                    >
                                                                                        {/* <a href={(!termsAndConditions) ? `javascript:void(0)` : `javascript:void(0)`} onClick={() => { isValidated ? event?.preventDefault : event?.preventDefault }}>{`Pay $${productData.ProductPrice} & Submit`}</a> */}
                                                                                        {documentType !== 'CRG' && (
                                                                                            <button
                                                                                                className="cco_form_submit"
                                                                                                disabled={
                                                                                                    !termsAndConditions
                                                                                                }
                                                                                                type={'submit'}
                                                                                                style={{
                                                                                                    opacity:
                                                                                                        termsAndConditions
                                                                                                            ? '1'
                                                                                                            : '.5',
                                                                                                    cursor: termsAndConditions
                                                                                                        ? 'pointer'
                                                                                                        : 'not-allowed',
                                                                                                    fontSize: '16px',
                                                                                                }}
                                                                                            >
                                                                                                {isLoading
                                                                                                    ? 'Generating PDF'
                                                                                                    :t("GeneratePdf.Sheet.sheet2")}
                                                                                            </button>
                                                                                        )}
                                                                                    </li>
                                                                                    <li
                                                                                        className={`cco_menu_item cco_menu_item_btn   ${
                                                                                            !termsAndConditions
                                                                                                ? 'cco_menu_item_btn_disabled'
                                                                                                : ''
                                                                                        }`}
                                                                                    >
                                                                                        {/* <a href={(!termsAndConditions) ? `javascript:void(0)` : `javascript:void(0)`} onClick={() => { isValidated ? event?.preventDefault : event?.preventDefault }}>{`Pay $${productData.ProductPrice} & Submit`}</a> */}

                                                                                        {documentType === 'CRG' && (
                                                                                            <button
                                                                                                className="cco_form_submit"
                                                                                                disabled={
                                                                                                    !termsAndConditions
                                                                                                }
                                                                                                type={'submit'}
                                                                                                style={{
                                                                                                    opacity:
                                                                                                        termsAndConditions
                                                                                                            ? '1'
                                                                                                            : '.5',
                                                                                                    cursor: termsAndConditions
                                                                                                        ? 'pointer'
                                                                                                        : 'not-allowed',
                                                                                                    fontSize: '16px',
                                                                                                }}
                                                                                            >
                                                                                                {isLoading
                                                                                                    ? 'Generating PDF'
                                                                                                    :t("GeneratePdf.Sheet.sheet3")}
                                                                                            </button>
                                                                                        )}
                                                                                    </li>
                                                                                    <li
                                                                                        className={`cco_menu_item cco_menu_item_btn`}
                                                                                    >
                                                                                        <button
                                                                                            className="cco_form_submit"
                                                                                            onClick={() => {
                                                                                                setDocumentType('');
                                                                                                generatePDFMutation.reset();
                                                                                            }}
                                                                                            type={'button'}
                                                                                            style={{ fontSize: '16px' }}
                                                                                        >
                                                                                            {t("GeneratePdf.Headerpage1.back")}
                                                                                        </button>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    )}
                                </>
                            )}
                        </>
                    </div>
                </div>
            </div>

            <BlockUI
                title="Generating PDF Please wait..."
                blocking={isLoading}
            />
        </article></div></div>
    );
};
