import React from 'react';
import ReactModal from 'react-modal';

const LanguageModal = ({ isOpen, onClose, languageOptions, changeLanguage }) => {
  const customStyles = {
    content: {
      position: 'fixed',
      bottom: '0',
      left: '0',
      right: '0',
      padding: '20px',
    //   borderRadius: '10px 10px 0 0',
      backgroundColor: '#fff',
      boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
      border: 'none',
      width: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
      zIndex: 1000,
    },
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      closeTimeoutMS={300}
      ariaHideApp={false}
      className="animate-bottom"
    >
  

      {/* Language Options */}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {languageOptions.map((option) => (
          <button
            key={option.value}
            onClick={() => {
              changeLanguage(option.value);
              onClose();
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '20px 10px',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              width: '100%',
              textAlign: 'left',
              borderBottom: '1px solid #eee',
            }}
          >
            <img
              src={option.flagUrl}
              alt={`${option.label} flag`}
              style={{ width: '25x', height: '25px', marginRight: '15px' }}
            />
            <span style={{ fontSize: '20px', color: '#333' }}>{option.label}</span>
          </button>
        ))}
      </div>
    </ReactModal>
  );
};

export default LanguageModal;