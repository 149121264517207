import type { IValues } from './valuesTypes';

import { Loader } from '../../components/Loader';
import { useStates } from '../../hooks/useStates';
import { useCountries } from '../../hooks/useCountries';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { useTimezone } from '../../hooks/useTimezones';
import { useTranslation } from 'react-i18next';

interface ILocationInformationProps {
    singlePage?: boolean;
}

export const LocationInformation = ({ singlePage = false }: ILocationInformationProps) => {
    const { t} = useTranslation();
    const countriesQuery = useCountries();
    const timezoneQuery=useTimezone();
    const formik = useFormikContext<IValues>();
    const countriesData = countriesQuery.data?.data.Data || [];
    const timezonesData = timezoneQuery.data?.data.Data || [];


    const statesQuery = useStates('default' === formik.values.country ? undefined : formik.values.country);
    const statesLoading = 'idle' !== statesQuery.fetchStatus && statesQuery.isLoading;
    const statesData = statesQuery.data?.data.Data || [];

    return (
        <div className="cco_form_row">
            <div className="cco_form_col">
                <h2 className="cco_form_heading"> <strong>{t("NewLocation.TestSite.testSite")} </strong> </h2>

                <div className="cco_form_wrap">
                    <ul className="cco_form_fieldset">
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoCompanyName">{t("NewLocation.TestSite.FacilityTest")} <span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoCompanyName" name="companyName" disabled={singlePage} />

                            <ErrorMessage component={'div'} name={'companyName'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoStreet1">{t("NewLocation.TestSite.Street1")} <span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoStreet1" name="street1" disabled={singlePage} />

                            <ErrorMessage component={'div'} name={'street1'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoStreet2">
                            {t("NewLocation.TestSite.street2")} <small>{t("NewLocation.TestSite.eg")}</small>
                            </label>
                            <Field type="text" id="ccoStreet2" name="street2" disabled={singlePage} />

                            <ErrorMessage component={'div'} name={'street2'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoCountry">{t("NewLocation.TestSite.country")} <span style={{ color: '#db3a3a' }}>*</span></label>

                            {countriesQuery.isLoading && <Loader scale={0.6} fieldset={true} />}

                            <Field as="select" id="ccoCountry" name="country" disabled={countriesQuery.isLoading || singlePage}>
                                <option  value="default" disabled={true}>
                                {countriesQuery.isLoading ? 'Loading...' : t("NewLocation.TestSite.selectcountry")}

                                </option>

                                {countriesData.map(country => {
                                    return (
                                        <option key={country.CountryId} value={country.CountryId}>
                                            {country.CountryName}
                                        </option>
                                    );
                                })}
                            </Field>

                            <ErrorMessage component={'div'} name={'country'} className="cco_form_message cco_form_message_error" />

                            {countriesQuery.error && <div className="cco_form_message cco_form_message_error">{countriesQuery.error.message}</div>}

                            {countriesQuery.data && countriesData.length < 1 && (
                                <div className="cco_form_message cco_form_message_error">{t("NewLocation.TestSite.nocountry")}</div>
                            )}
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoCity"> {t("NewLocation.TestSite.city")}<span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoCity" name="city" disabled={singlePage} />

                            <ErrorMessage component={'div'} name={'city'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li
                            className="cco_form_fieldset_item cco_form_fieldset_item_medium"
                            data-tooltip={'default' === formik.values.country ? 'Select a country first' : undefined}>
                            <label htmlFor="ccoState">{t("NewLocation.TestSite.state")} <span style={{ color: '#db3a3a' }}>*</span></label>

                            {statesLoading && <Loader scale={0.6} fieldset={true} />}

                            <Field as="select" id="ccoState" name="state" disabled={'default' === formik.values.country || statesLoading || singlePage}>
                                <option value="default" disabled={true}>
                                    {statesLoading ? 'Loading...' : t("NewLocation.TestSite.selectstate") }
                                </option>

                                {statesData.map(state => (
                                    <option key={state.ProvinceId} value={state.ProvinceId}>
                                        {state.ProvinceName}
                                    </option>
                                ))}
                            </Field>

                            <ErrorMessage component={'div'} name={'state'} className="cco_form_message cco_form_message_error" />

                            {statesQuery.error && <div className="cco_form_message cco_form_message_error">{statesQuery.error.message}</div>}

                            {statesQuery.data && statesData.length < 1 && (
                                <div className="cco_form_message cco_form_message_error">{t("NewLocation.TestSite.nostates")}</div>
                            )}
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoZip"> {t("NewLocation.TestSite.zip")} <span style={{ color: '#db3a3a' }}>*</span></label>
                            <Field type="text" id="ccoZip" name="zip" disabled={singlePage} />

                            <ErrorMessage component={'div'} name={'zip'} className="cco_form_message cco_form_message_error" />
                        </li>
                  

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoSecureFacility"> {t("NewLocation.TestSite.securefacility")}<span style={{ color: '#db3a3a' }}>*</span></label>

                            <Field as="select" id="ccoSecureFacility" name="isASecureFacility">
                                <option value="default" disabled={true}>
                                {t("NewLocation.TestSite.option")}
                                </option>

                                <option value="yes">{t("NewLocation.TestSite.YES")}</option>
                                <option value="no"> {t("NewLocation.TestSite.NO")} </option>
                            </Field>

                            <ErrorMessage component={'div'} name={'isASecureFacility'} className="cco_form_message cco_form_message_error" />
                        </li>

                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label htmlFor="ccoTimezone">{t("NewLocation.TestSite.Timezone")} <span style={{ color: '#db3a3a' }}>*</span></label>

                            {timezoneQuery.isLoading && <Loader scale={0.6} fieldset={true} />}

                            <Field as="select" id="ccoTimezone" name="timezone" disabled={timezoneQuery.isLoading || singlePage}>
                                <option value="default" disabled={true}>
                                    {timezoneQuery.isLoading ? 'Loading...' : "Select a Timezone" }
                                </option>

                                {timezonesData.map(timezone => {
                                    return (
                                        <option key={timezone.TimeZoneId} value={timezone.TimeZoneId}>
                                            {timezone.PrimaryName}
                                        </option>
                                    );
                                })}
                            </Field>

                            <ErrorMessage component={'div'} name={'timezone'} className="cco_form_message cco_form_message_error" />

                            {timezoneQuery.error && <div className="cco_form_message cco_form_message_error">{timezoneQuery.error.message}</div>}

                            {timezoneQuery.data && timezonesData.length < 1 && (
                                <div className="cco_form_message cco_form_message_error">{t("NewLocation.TestSite.notimezone")}</div>
                            )}
                        </li>

                        {'yes' === formik.values.isASecureFacility && (
                            <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                <label htmlFor="additionalSecurity">
                                {t("NewLocation.TestSite.Additional")} <span style={{ color: '#db3a3a' }}>*</span>
                                </label>

                                <Field rows={10} as="textarea" id="additionalSecurity" name="additionalSecurity" />

                                <ErrorMessage
                                    component={'div'}
                                    name={'additionalSecurity'}
                                    className="cco_form_message cco_form_message_error"
                                />
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
