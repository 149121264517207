import InputMask from 'react-input-mask';
import moment from 'moment';
import type { Root } from '../../types/dashboard';
import { Loader } from '../../components/Loader'; 
import { useStates } from '../../hooks/useStates';
import { accountSchema } from '../../schemas/account';
import { useCountries } from '../../hooks/useCountries';
import { useMutationQuery } from '../../hooks/useMutationQuery';
import { useAccountMutation } from '../../hooks/useAccountMutation';
import { useUser, useUserProfilePicture } from '../../hooks/useUser';
import { Formik, Field, ErrorMessage, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

interface IValues {
    zip: string;
    city: string;
    phone: string;
    state: string;
    country: string;
    street1: string;
    street2: string;
    profilePicture: null | string | File;
    employerOrganization: string;
    employerAddress1: string;
    employerAddress2: string;
    employerPhone: string;
    employerState: string;
    employerCountry: string;
    employerCity: string;
    employerZip: string;
}

const params = new URL(window.location as any).searchParams;
const incomplete = null !== params.get('incomplete');

const EmployerStates = () => {
    const formik = useFormikContext<IValues>();
    const { t } = useTranslation();
    const statesQuery = useStates('default' === formik.values.employerCountry ? undefined : formik.values.employerCountry);
    const statesLoading = 'idle' !== statesQuery.fetchStatus && statesQuery.isLoading;
    const statesData = statesQuery.data?.data.Data || [];

    return (
        <li
            className="cco_form_fieldset_item cco_form_fieldset_item_small"
            data-tooltip={'default' === formik.values.employerCountry ? t("account.personalForm.selectCountry") : undefined}>
            <label htmlFor="employerState"> {t("account.personalForm.state")}</label>

            {statesLoading && <Loader scale={0.6} fieldset={true} />}

            <Field as="select" id="employerState" name="employerState" disabled={'default' === formik.values.employerCountry || statesLoading}>
                <option value="default" disabled={true}>
                    {statesLoading ? 'Loading...' : t("account.personalForm.selectState")}
                </option>

                {statesData.map(state => (
                    <option key={state.ProvinceId} value={state.ProvinceId}>
                        {state.ProvinceName}
                    </option>
                ))}
            </Field>

            <ErrorMessage component={'div'} name={'state'} className="cco_form_message cco_form_message_error" />

            {statesQuery.error && <div className="cco_form_message cco_form_message_error">{statesQuery.error.message}</div>}

            {statesQuery.data && statesData.length < 1 && (
                <div className="cco_form_message cco_form_message_error">{t("account.personalForm.noState")}</div>
            )}
        </li>
    );
};

const States = () => {
    const formik = useFormikContext<IValues>();
    const { t } = useTranslation();
    const statesQuery = useStates('default' === formik.values.country ? undefined : formik.values.country);
    const statesLoading = 'idle' !== statesQuery.fetchStatus && statesQuery.isLoading;
    const statesData = statesQuery.data?.data.Data || [];

    return (
        <li
            className="cco_form_fieldset_item cco_form_fieldset_item_small"
            data-tooltip={'default' === formik.values.country ? t("account.personalForm.selectCountry") : undefined}>
            <label htmlFor="ccoState">
            {t("account.personalForm.state")}<span style={{ color: '#db3a3a' }}>*</span>
            </label>

            {statesLoading && <Loader scale={0.6} fieldset={true} />}

            <Field as="select" id="ccoState" name="state" disabled={'default' === formik.values.country || statesLoading}>
                <option value="default" disabled={true}>
                    {statesLoading ? 'Loading...' : t("account.personalForm.selectState")}
                </option>

                {statesData.map(state => (
                    <option key={state.ProvinceId} value={state.ProvinceId}>
                        {state.ProvinceName}
                    </option>
                ))}
            </Field>

            <ErrorMessage component={'div'} name={'state'} className="cco_form_message cco_form_message_error" />

            {statesQuery.error && <div className="cco_form_message cco_form_message_error">{statesQuery.error.message}</div>}

            {statesQuery.data && statesData.length < 1 && (
                <div className="cco_form_message cco_form_message_error">{t("account.personalForm.noState")}</div>
            )}
        </li>
    );
};

export const Account = ({ headerMarginTop }: { headerMarginTop: number }) => {
    const { t } = useTranslation();
    const query = useUser();
    const countriesQuery = useCountries();
    const constriesEmployerQuery = useCountries();
    const accountMutation = useAccountMutation();
    const updateProfilePicture = useMutationQuery();
    const { data: profilePictureQuery, isLoading: profilePictureQueryLoadingStatus } = useUserProfilePicture();

    const countriesData = countriesQuery.data?.data.Data || [];
    const employerCountriesData = constriesEmployerQuery.data?.data.Data || [];
    const data = (query.data?.data || {}) as unknown as Root['data'];

    const initialPhone = data?.PhoneNumbers?.[0]?.Phone || '';
    const sanitizedPhone = initialPhone.replace(/\D/g, '');
    const formattedPhone = sanitizedPhone
    ? `+1 (${sanitizedPhone.slice(0, 3)}) ${sanitizedPhone.slice(3, 6)}-${sanitizedPhone.slice(6)}`
    : '';

    const initialValues: IValues = {
        zip: data?.Addresses?.[0]?.Zip || '',
        city: data?.Addresses?.[0]?.City || '',
        phone: formattedPhone,
        // phone: data?.PhoneNumbers?.[0]?.Phone || '',      
        street1: data?.Addresses?.[0]?.Street1 || '',
        street2: data?.Addresses?.[0]?.Street2 || '',
        profilePicture: data.myCCOPicture || null,
        state: data?.Addresses?.[0]?.StateId || 'default',
        country: data?.Addresses?.[0]?.CountryId || 'default',
        employerOrganization: data?.Employer?.Organization || '',
        employerAddress1: data?.Employer?.Address1 || '',
        employerAddress2: data?.Employer?.Address2 || '',
        employerPhone: data?.Employer?.Phone || '',
        employerState: data?.Employer?.StateId || 'default',
        employerCountry: data?.Employer?.CountryId || 'default',
        employerCity: data?.Employer?.City || '',
        employerZip: data?.Employer?.Zip || ''
    };
    
    return (

        <div id="main-content" style={{ marginTop: headerMarginTop }}>
        <div className="cco_content_area cco_content_area_0">
            <article className="react-container">
       
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={accountSchema}
            onSubmit={(values, { setSubmitting }) => {
                const controllerChangeQueries = [];

                const data = {
                    PhoneNumbers: [
                        {
                            Type: 'MOBILE',
                            Phone: values.phone,
                        }
                    ],
                    Addresses: [
                        {
                            Type: 'HOME',
                            Zip: values.zip,
                            City: values.city,
                            StateId: values.state,
                            Street2: values.street2,
                            CountryId: values.country,
                            Street1: values.street1 || null
                        }
                    ],
                    Employer: {
                        Organization: values.employerOrganization,
                        Address1: values.employerAddress1,
                        Address2: values.employerAddress2,
                        Phone: values.employerPhone,
                        StateId: 'default' === values.employerState ? null : values.employerState,
                        CountryId: 'default' === values.employerCountry ? null : values.employerCountry,
                        City: values.employerCity,
                        Zip: values.employerZip
                    }
                };

                if (values.profilePicture instanceof File) {
                    const formData = new FormData();

                    formData.append('file', values.profilePicture);
                    formData.append('security', '4a46c461ae');
                    formData.append('action', 'updateProfilePicture');

                    controllerChangeQueries.push(updateProfilePicture.mutateAsync(formData));
                }

                controllerChangeQueries.push(accountMutation.mutateAsync(data));

                Promise.all(controllerChangeQueries).finally(() => setSubmitting(false));
            }}>
            {({ values, /*setFieldValue,*/ isSubmitting,  handleBlur, handleChange, handleSubmit, handleReset }) => (
                <form
                    className="cco_form"
                    onReset={handleReset}
                    onSubmit={e => {
                        console.log(values);
                        if (isSubmitting) e.preventDefault();
                        else handleSubmit(e);
                    }}>
                    <div className="cco_section cco_section_0">
                        <div className="cco_row">
                            <div className="cco_col">
                                {incomplete && (
                                    <div className="cco_text cco_text_12">
                                        <div className="cco_text_inner">
                                            <p>{t("account.incompleteProfile")}</p>
                                        </div>
                                    </div>
                                )}

                                <div className="cco_text cco_text_5">
                                    <div className="cco_text_inner">
                                        <h1>{t("account.pageHeading")}</h1>
                                    </div>
                                </div>

                                {query.isLoading || profilePictureQueryLoadingStatus ? (
                                    <div className="cco_form_wrap">
                                        <Loader />
                                    </div>
                                ) : query.error ? (
                                    <div className="cco_form_wrap">
                                        <p>{query.error.message}</p>
                                    </div>
                                ) : (
                                    <>
                                        <div className="cco_form_row">
                                            <div className="cco_form_col">
                                                <h2 className="cco_form_heading"> <strong>{t("account.personalInfo")} </strong></h2>

                                                <div className="cco_form_wrap">
                                                    <ul className="cco_form_fieldset">
                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoFullName">{t("account.personalForm.fullName")}</label>

                                                            <input disabled type="text" id="ccoFullName" defaultValue={data?.Name?.FullName} />
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoDateOfBirth">{t("account.personalForm.dob")}</label>

                                                            <input
                                                                disabled
                                                                type="text"
                                                                id="ccoDateOfBirth"
                                                                defaultValue={moment(data?.DateOfBirth.toString()).format('MM/DD/YYYY') || 'Not Available'}
                                                            />
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoEmail">{t("account.personalForm.email")}</label>

                                                            <input disabled type="email" id="ccoEmail" defaultValue={data?.EmailAddresses?.[0]?.Email} />
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoPhone">
                                                            {t("account.personalForm.mobilePhone")} <span style={{ color: '#db3a3a' }}>*</span>
                                                            </label>

                                                            <InputMask
                                                                type={'tel'}
                                                                id={'ccoPhone'}
                                                                value={values.phone}
                                                                mask="+1 (999) 999-9999"
                                                                onChange={handleChange('phone')}
                                                                onBlur={handleBlur('phone')}
                                                            />
                                                            {/* <Field type="tel" id="ccoPhone" defaultValue={data?.PhoneNumbers?.[0]?.Phone} name="phone" /> */}
                                                          
                                                            {/* <input name='phone'  type="tel" id="ccoPhone" defaultValue={data?.PhoneNumbers?.[0]?.Phone} /> */}
                                                     

                                                            <ErrorMessage
                                                                name={'phone'}
                                                                component={'div'}
                                                                className="cco_form_message cco_form_message_error"
                                                            />
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoStreet1">
                                                            {t("account.personalForm.street1")}  <span style={{ color: '#db3a3a' }}>*</span>
                                                            </label>

                                                            <Field type="text" id="ccoStreet1" name="street1" />

                                                            <ErrorMessage
                                                                name={'street1'}
                                                                component={'div'}
                                                                className="cco_form_message cco_form_message_error"
                                                            />
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoStreet2">{t("account.personalForm.street2")}</label>

                                                            <Field type="text" id="ccoStreet2" name="street2" />

                                                            <ErrorMessage
                                                                name={'street2'}
                                                                component={'div'}
                                                                className="cco_form_message cco_form_message_error"
                                                            />
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoCountry">
                                                            {t("account.personalForm.country")}  <span style={{ color: '#db3a3a' }}>*</span>
                                                            </label>

                                                            {countriesQuery.isLoading && <Loader scale={0.6} fieldset={true} />}

                                                            <Field as="select" id="ccoCountry" name="country" disabled={countriesQuery.isLoading}>
                                                                <option value="default" disabled={true}>
                                                                    {countriesQuery.isLoading ? 'Loading...' : t("account.personalForm.selectaCountry")}
                                                                </option>

                                                                {countriesData.map(country => {
                                                                    return (
                                                                        <option key={country.CountryId} value={country.CountryId}>
                                                                            {country.CountryName}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Field>

                                                            <ErrorMessage
                                                                component={'div'}
                                                                name={'country'}
                                                                className="cco_form_message cco_form_message_error"
                                                            />

                                                            {countriesQuery.error && (
                                                                <div className="cco_form_message cco_form_message_error">{countriesQuery.error.message}</div>
                                                            )}

                                                            {countriesQuery.data && countriesData.length < 1 && (
                                                                <div className="cco_form_message cco_form_message_error">
                                                                    {t("account.personalForm.noCountry")} 
                                                                    
                                                                </div>
                                                            )}
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoCity">
                                                            {t("account.personalForm.city")}   <span style={{ color: '#db3a3a' }}>*</span>
                                                            </label>

                                                            <Field type="text" id="ccoCity" name="city" />

                                                            <ErrorMessage name={'city'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                        </li>

                                                        <States />

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoZip">
                                                            {t("account.personalForm.zip")} <span style={{ color: '#db3a3a' }}>*</span>
                                                            </label>

                                                            <Field type="text" id="ccoZip" name="zip" />

                                                            <ErrorMessage name={'zip'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cco_form_row">
                                            <div className="cco_form_col">
                                                <h2 className="cco_form_heading"><strong>{t("account.employeeInfo")} </strong></h2>

                                                <div className="cco_form_wrap">
                                                    <ul className="cco_form_fieldset">
                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoOrganization">{t("account.employeeForm.organization")}</label>

                                                            <Field type="text" id="ccoOrganization" name="employerOrganization" />

                                                            <ErrorMessage
                                                                name={'employerOrganization'}
                                                                component={'div'}
                                                                className="cco_form_message cco_form_message_error"
                                                            />
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                            <label htmlFor="ccoEmployerPhone">{t("account.employeeForm.phone")}</label>

                                                            <InputMask
                                                                type={'tel'}
                                                                id={'ccoEmployerPhone'}
                                                                value={values.employerPhone}
                                                                mask="+1 (999) 999-9999"
                                                                onChange={handleChange('employerPhone')}
                                                                onBlur={handleBlur('employerPhone')}
                                                            />

                                                            {/* <Field type="tel" id="ccoEmployerPhone" defaultValue={data?.Employer.Phone} name="employerPhone" /> */}
                                                           
                                                            <ErrorMessage
                                                                name={'employerPhone'}
                                                                component={'div'}
                                                                className="cco_form_message cco_form_message_error"
                                                            />
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoAdress1">{t("account.employeeForm.address1")}</label>

                                                            <Field type="text" id="ccoAdress1" name="employerAddress1" />

                                                            <ErrorMessage
                                                                name={'employerAddress1'}
                                                                component={'div'}
                                                                className="cco_form_message cco_form_message_error"
                                                            />
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoAddress2">{t("account.employeeForm.address2")}</label>

                                                            <Field type="text" id="ccoAddress2" name="employerAddress2" />

                                                            <ErrorMessage
                                                                name={'employerAddress2'}
                                                                component={'div'}
                                                                className="cco_form_message cco_form_message_error"
                                                            />
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoEmployerountry">{t("account.employeeForm.country")}</label>

                                                            {constriesEmployerQuery.isLoading && <Loader scale={0.6} fieldset={true} />}

                                                            <Field
                                                                as="select"
                                                                id="ccoEmployerountry"
                                                                name="employerCountry"
                                                                disabled={constriesEmployerQuery.isLoading}>
                                                                <option value="default" disabled={true}>
                                                                    {constriesEmployerQuery.isLoading ? 'Loading...' : t("account.personalForm.selectaCountry")}
                                                                </option>

                                                                {employerCountriesData.map(country => {
                                                                    return (
                                                                        <option key={country.CountryId} value={country.CountryId}>
                                                                            {country.CountryName}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Field>

                                                            <ErrorMessage
                                                                component={'div'}
                                                                name={'employerCountry'}
                                                                className="cco_form_message cco_form_message_error"
                                                            />

                                                            {constriesEmployerQuery.error && (
                                                                <div className="cco_form_message cco_form_message_error">
                                                                    {constriesEmployerQuery.error.message}
                                                                </div>
                                                            )}

                                                            {constriesEmployerQuery.data && employerCountriesData.length < 1 && (
                                                                <div className="cco_form_message cco_form_message_error">
                                                                    {t("account.employeeForm.noCountry")}
                                                                </div>
                                                            )}
                                                        </li>

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoEmployerCity">{t("account.employeeForm.city")}</label>

                                                            <Field type="text" id="ccoEmployerCity" name="employerCity" />

                                                            <ErrorMessage
                                                                name={'employerCity'}
                                                                component={'div'}
                                                                className="cco_form_message cco_form_message_error"
                                                            />
                                                        </li>

                                                        <EmployerStates />

                                                        <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                            <label htmlFor="ccoEmployerZip">{t("account.employeeForm.zip")}</label>

                                                            <Field type="text" id="ccoEmployerZip" name="employerZip" />

                                                            <ErrorMessage
                                                                name={'employerZip'}
                                                                component={'div'}
                                                                className="cco_form_message cco_form_message_error"
                                                            />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="cco_form_row">
                                            <div className="cco_form_col cco_form_col_0">
                                                <h2 className="cco_form_heading">2. Personal Photo</h2>
                                                <div className="cco_form_wrap">
                                                    <label htmlFor="ccoProfilePhoto">Upload Profile Photo</label>

                                                    <div className="cco_form_field cco_form_field_0">
                                                        <div className="cco_form_field_body">
                                                            <label className="cco_form_field_label ccs_loader_relative_container" htmlFor="ccoProfilePhoto">
                                                                {values.profilePicture ? (
                                                                    <>
                                                                        <img
                                                                            alt={'The user profile picture'}
                                                                            onLoad={e => {
                                                                                const src = e.currentTarget.src;

                                                                                if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                                                            }}
                                                                            src={
                                                                                values.profilePicture instanceof File
                                                                                    ? URL.createObjectURL(values.profilePicture)
                                                                                    : values.profilePicture
                                                                            }
                                                                        />

                                                                        <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">
                                                                            Upload Photo
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="cco_form_field_label_icon cco_form_field_label_icon_profile"></span>
                                                                        <span className="cco_form_field_label_caption">Upload Photo</span>
                                                                    </>
                                                                )}

                                                                {updateProfilePicture.isLoading && (
                                                                    <div className="ccs_loader_container">
                                                                        <Loader />
                                                                    </div>
                                                                )}
                                                            </label>

                                                            <Field
                                                                type={'file'}
                                                                value={undefined}
                                                                accept={'image/*'}
                                                                id={'ccoProfilePhoto'}
                                                                className={'cco_form_field_input'}
                                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                                    const file = event.currentTarget.files?.[0];

                                                                    if (file) {
                                                                        setFieldValue('profilePicture', file);
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="cco_form_field_aside">
                                                            {!isSubmitting && updateProfilePicture.data && (
                                                                <div className="cco_form_message cco_form_message_has_icon cco_form_message_success">
                                                                    Successfully updated your profile picture
                                                                </div>
                                                            )}

                                                            {!isSubmitting && updateProfilePicture.error && (
                                                                <div className="cco_form_message cco_form_message_has_icon cco_form_message_error">
                                                                    {updateProfilePicture.error.message}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="cco_form_row">
                                            <div className="cco_form_col">
                                                {!isSubmitting && (
                                                    <>
                                                        {accountMutation.data?.success && (
                                                            <div
                                                                className="cco_form_message cco_form_message_success"
                                                                style={{
                                                                    fontSize: 16,
                                                                    marginBottom: 20
                                                                }}>
                                                                <strong>
                                                                {t("account.successMessage")}
                                                                </strong>
                                                            </div>
                                                        )}

                                                        {accountMutation.error && (
                                                            <div
                                                                className="cco_form_message cco_form_message_error"
                                                                style={{
                                                                    marginBottom: 20
                                                                }}>
                                                                {(accountMutation.error as Error).message}
                                                            </div>
                                                        )}
                                                    </>
                                                )}

                                                {/* <button className="cco_form_submit" type={'submit'}>
                                                    {isSubmitting ? 'Saving...' : 'Save Changes'}
                                                </button> */}

                                                <ul className="cco_menu cco_menu_7">
                                                    <li className="cco_menu_item">
                                                        <button className="cco_form_submit" type={'submit'}>
                                                       
                                                            {isSubmitting ? t("account.saving")  : t("account.saveChangesButton")}
                                                        </button>
                                                    </li>

                                                    {!isSubmitting && accountMutation.data?.success && (
                                                        <li className="cco_menu_item">
                                                            <a href="/dashboard">{t("account.returnDashboardButton")}</a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
        </article>
        </div>
        </div>
    );
};
