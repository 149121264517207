import React, { useState } from 'react';
import Modal from 'react-modal';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
} from 'react-share';
import { FaShareAlt, FaSms } from 'react-icons/fa'; // Import a custom SMS icon or use any other library
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../hooks/Helper/useIsMobile';
import { QRCode } from 'react-qrcode-logo';



Modal.setAppElement('#root');

interface ShareModalProps {
  CCOID: string;
  Name: string;
}

const ShareModal: React.FC<ShareModalProps> = ({ CCOID, Name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
      const { t } = useTranslation();

      const isMobile=useIsMobile();

  const shareUrl = `https://www.verifycco.org/cert/${CCOID}`;
  const shareMessage = t("dashboard.myCertificationCard.shareModal.shareMessage", { 
    name: Name, 
    url: shareUrl 
  });
  const shareTitle = t("dashboard.myCertificationCard.shareModal.shareSubject", { 
    name: Name
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopySuccess(t("dashboard.myCertificationCard.shareModal.copiedMessage"));
      setTimeout(() => setCopySuccess(''), 2000);
    });
  };

  const handleSmsClick = () => {
    window.location.href = `sms:?&body=${encodeURIComponent(shareMessage)}`;
  };

  return (
    <div>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          openModal();
        }}
        style={{
          width: '100%',
          marginLeft: '10px',
          height: '40px',
          padding: '10px',
          backgroundColor: '#c67b05',
          color: 'white',
          textDecoration: 'none',
          borderRadius: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
       <FaShareAlt /> {t("dashboard.myCertificationCard.shareModal.shareButton")}
      </a>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Share Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 20px', 

          },
          content: {
            position: 'relative',
            width: '400px',
            padding: '20px',
            borderRadius: '8px',
            inset: 'unset',
            backgroundColor: 'white',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          },
        }}
      >
        <button
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            border: 'none',
            background: 'transparent',
            fontSize: '1.5rem',
          }}
        >
          &#10005;
        </button>

        <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>{t("dashboard.myCertificationCard.shareModal.title")}</h2>

        <div style={{ display: 'flex', gap: '15px',flexWrap:"wrap", marginBottom: '20px', justifyContent: 'center' }}>
        <EmailShareButton url={''} subject={shareTitle} body={shareMessage}>
            <EmailIcon size={40}  round/>
          </EmailShareButton>
        {isMobile && 
        <button style={{ backgroundColor: 'transparent',color: 'gray',marginLeft: '', border: 'none', cursor: 'pointer' }}
        onClick={handleSmsClick}
        >
          <FaSms size={40} />
        </button>
        }  
          <WhatsappShareButton  url={shareMessage}>
            <WhatsappIcon size={40} round />
          </WhatsappShareButton>
          <div>

    </div>
      
        </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
      <QRCode logoImage='/static/media/Logo_CCO_2.c0fa8490d1c7b4bb6278.png' logoWidth={40} logoHeight={40} value={shareUrl} size={120} qrStyle='dots' />
      </div>  

        {/* Input and Copy Button Container */}
        <div style={{ position: 'relative', marginBottom: '20px' }}>
          <input
            type="text"
            value={shareUrl}
            readOnly
            style={{
              width: '100%',
              padding: '10px',
              paddingRight: '80px', // Space for the button
              borderRadius: '10px',
              border: '2px solid whitesmoke',
            }}
          />
          <button
            onClick={copyToClipboard}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              padding: '6px 10px',
              backgroundColor: '#c67b05',
              color: 'white',
              border: 'none',
              borderRadius: '20px',
              cursor: 'pointer',
            }}
          >
            {t("dashboard.myCertificationCard.shareModal.copyButton")}
          </button>
        </div>

        {copySuccess && (
          <p style={{ color: 'green', fontSize: '0.9rem', textAlign: 'center' }}>{copySuccess}</p>
        )}
      </Modal>
    </div>
  );
};

export default ShareModal;
